import { originationPagePaths } from '@/routes/originationRoutes'
import { mailerOriginationPagePaths } from '@/routes/mailerOriginationRoutes'
import { marketingPagePaths } from '@/routes/marketingRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'

export const mailerOriginationEntryPagePaths: string[] = [marketingPagePaths.LANDING, marketingPagePaths.LANDING_JOIN, marketingPagePaths.LANDING_INVITE, ...Object.values(experimentPagePaths)]

export const mailerOriginationReturnPagePathPairs: string[][] = []

// Note that the mailerOriginationFlow actually transitions to the originationFlow
// starting at VerifyIdentity.vue using a call to:
// appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
export const mailerOriginationFlow: string[] = [
    originationPagePaths.STATED_USAGE,
    mailerOriginationPagePaths.STATED_INCOME,
    mailerOriginationPagePaths.PRE_QUALIFICATION,
    mailerOriginationPagePaths.PERSONAL_INFO,
    originationPagePaths.VERIFY_IDENTITY,
]

export const mailerOriginationBackGuardPagePaths: string[] = [mailerOriginationPagePaths.PERSONAL_INFO]

const ssnVerificationFlow: string[] = [originationPagePaths.SSN_VERIFICATION]
const coApplicantSsnVerificationFlow: string[] = [originationPagePaths.CO_APPLICANT_SSN_VERIFICATION]
const experianFrozenFlow: string[] = [originationPagePaths.EXPERIAN_FROZEN]
const coApplicantExperianFrozenFlow: string[] = [originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN]
// we have to define addCoApplicantOnFailureFlow here to redirect DM applicants to the correct page.
// flow for individual DM applicant who fails DTI only = express/pre_qualification -> express/personal_info -> origination/verify_identity -> origination/prequalificaton -> origination/offerAddCoApplicant
const addCoApplicantOnFailureFlow: string[] = [mailerOriginationPagePaths.PERSONAL_INFO, originationPagePaths.VERIFY_IDENTITY]
const remedyNameMismatchFlow = [originationPagePaths.REMEDY_NAME_MISMATCH]
const preQualificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.experianFrozen, experianFrozenFlow],
    [RouteOption.coApplicantExperianFrozen, coApplicantExperianFrozenFlow],
    [RouteOption.ssnVerification, ssnVerificationFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
    [RouteOption.addCoApplicantOnFailure, addCoApplicantOnFailureFlow],
    [RouteOption.remedyNameMismatch, remedyNameMismatchFlow],
])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([[mailerOriginationPagePaths.PRE_QUALIFICATION, preQualificationFlowGroup]])

// Could be a unit test
if (!checkAreAllPathsUnique(mailerOriginationFlow, optionalFlowMap)) {
    throw new Error('mailer origination page paths not unique!')
}

export const getMailerOriginationNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (mailerOriginationEntryPagePaths.findIndex((path) => checkPathsMatch(path, currentPath)) >= 0) {
        return mailerOriginationFlow[0]
    }

    for (const [path, nextPath] of mailerOriginationReturnPagePathPairs) {
        if (checkPathsMatch(path, currentPath)) {
            return nextPath
        }
    }

    let nextPath: string | null
    if (routeOption) {
        nextPath = tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    } else {
        nextPath = tryGetNextFlowPath(currentPath, mailerOriginationFlow, optionalFlowMap)
    }
    return nextPath
}
