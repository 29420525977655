import assert from 'assert'
import { ExperimentName } from '@/experiments/experimentName'
import { HeraclesParameter } from '@/experiments/heraclesParameter'
import { PreJuly2021DefaultPolicy } from '@/experiments/preJuly2021Default/preJuly2021DefaultPolicy'
import { Batch14ControlPolicy } from '@/experiments/batch14Control/batch14ControlPolicy'
import { Batch14OriginationFeePolicy } from '@/experiments/batch14OriginationFee/batch14OriginationFeePolicy'
import { Batch14MonthlyFeePolicy } from '@/experiments/batch14MonthlyFee/batch14MonthlyFeePolicy'
import { Batch14BtCashBackPolicy } from '@/experiments/batch14BtCashBack/batch14BtCashBackPolicy'
import { BeforeBatch14Policy } from '@/experiments/beforeBatch14/beforeBatch14Policy'
import { Batch15CashBackRedemptionPolicy } from '@/experiments/batch15CashBackRedemption/batch15CashBackRedemptionPolicy'
import { Batch16NewHomeownerPolicy } from '@/experiments/batch16NewHomeowner/batch16NewHomeownerPolicy'
import { MoapV1Policy } from '@/experiments/moapV1/moapV1Policy'
import { InternalHomeNoLoyaltyPolicy } from '@/experiments/internal/internalHomeNoLoyaltyPolicy'
import { InternalHomeLoyaltyPolicy } from '@/experiments/internal/internalHomeLoyaltyPolicy'
import { MoapV2Policy } from '@/experiments/moapV2/moapV2Policy'
import { PostJuly2021DefaultPolicy } from '@/experiments/postJuly2021Default/postJuly2021DefaultPolicy'
import { PostNov2021Policy } from '@/experiments/postNov2021/postNov2021Policy'
import { Default20210802Policy } from '@/experiments/default20210802/default20210802Policy'
import { SimpleAPRTablePolicy } from '@/experiments/simpleAPRTable/simpleAPRTablePolicy'
import { TestimonialsPolicy } from '@/experiments/testimonials/testimonials'
import { MoapV1NoBtFeePolicy } from '@/experiments/moapV1NoBtFee/moapV1NoBtFeePolicy'
import { BankratePolicy } from '@/experiments/bankrate/bankratePolicy'
import { DebtV1Policy } from '@/experiments/debtV1/debtV1Policy'
import { StartPolicy } from '@/experiments/start/startPolicy'
import { PostNov2021NoThirdLiensDirectMailPolicy } from '@/experiments/postNov2021NoThirdLiensDirectMail/postNov2021NoThirdLiensDirectMailPolicy'
import { PostNov2021NoThirdLiensPolicy } from '@/experiments/postNov2021NoThirdLiens/postNov2021NoThirdLiensPolicy'
import { PostMay2022Policy } from '@/experiments/postMay2022/postMay2022Policy'
import { PostMay2022FFTRULPolicy } from '@/experiments/postMay2022FFTRUL/postMay2022FFTRULPolicy'

const experimentToPolicyMap: { [T in ExperimentName]: HeraclesParameter } = {
    [ExperimentName.beforeBatch14]: new BeforeBatch14Policy(),
    [ExperimentName.batch14Control]: new Batch14ControlPolicy(),
    [ExperimentName.batch14BtCashBack]: new Batch14BtCashBackPolicy(),
    [ExperimentName.batch14MonthlyFee]: new Batch14MonthlyFeePolicy(),
    [ExperimentName.batch14OriginationFee]: new Batch14OriginationFeePolicy(),
    [ExperimentName.batch15CashBackRedemption]: new Batch15CashBackRedemptionPolicy(),
    [ExperimentName.batch16CashBackRedemption]: new Batch15CashBackRedemptionPolicy(), // Intentionally using the same policy as batch 15 for frontend
    [ExperimentName.batch17CashBackRedemption]: new Batch15CashBackRedemptionPolicy(), // Intentionally using the same policy as batch 15 for frontend
    [ExperimentName.batch18CashBackRedemption]: new Batch15CashBackRedemptionPolicy(), // Intentionally using the same policy as batch 15 for frontend
    [ExperimentName.batch16NewHomeowner]: new Batch16NewHomeownerPolicy(),
    [ExperimentName.moapV1]: new MoapV1Policy(),
    [ExperimentName.moapV1NoBtFee]: new MoapV1NoBtFeePolicy(),
    [ExperimentName.moapV2]: new MoapV2Policy(),
    [ExperimentName.preJuly2021Default]: new PreJuly2021DefaultPolicy(),
    [ExperimentName.postJuly2021Default]: new PostJuly2021DefaultPolicy(),
    [ExperimentName.default20210802]: new Default20210802Policy(),
    [ExperimentName.postNov2021]: new PostNov2021Policy(),
    [ExperimentName.postNov2021NoThirdLiens]: new PostNov2021NoThirdLiensPolicy(),
    [ExperimentName.postNov2021NoThirdLiensDirectMail]: new PostNov2021NoThirdLiensDirectMailPolicy(),
    [ExperimentName.postMay2022]: new PostMay2022Policy(),
    [ExperimentName.postMay2022FFTRUL]: new PostMay2022FFTRULPolicy(),
    [ExperimentName.internalHomeNoLoyalty]: new InternalHomeNoLoyaltyPolicy(),
    [ExperimentName.internalHomeLoyalty]: new InternalHomeLoyaltyPolicy(),
    [ExperimentName.simpleAPRTable]: new SimpleAPRTablePolicy(),
    [ExperimentName.testimonials]: new TestimonialsPolicy(),
    [ExperimentName.bankrate]: new BankratePolicy(),
    [ExperimentName.debtV1]: new DebtV1Policy(),
    [ExperimentName.start]: new StartPolicy(),
}

// Not foolproof, but helps try to catch missing experiment image folders before they make it to prod
for (const expPolicy of Object.values(experimentToPolicyMap)) {
    try {
        require(`@/assets/images/experiments/${expPolicy.EXPERIMENT_NAME}/index.ts`)
    } catch (e) {
        console.error(`Looks like you're missing the @/assets/images/experiments/${expPolicy.EXPERIMENT_NAME} folder!`)
        throw e
    }
}

export const getPolicyForExperiment = (exp: ExperimentName): HeraclesParameter => {
    assert(exp, 'Missing experiment name!')

    return experimentToPolicyMap[exp]
}

/**
 * Update when default policy changes!
 */
export const getDefaultPolicyName = (): ExperimentName => {
    return ExperimentName.postNov2021NoThirdLiens
}
