export enum ExperimentName {
    beforeBatch14 = 'beforeBatch14',
    batch14Control = 'batch14Control',
    batch14OriginationFee = 'batch14OriginationFee',
    batch14MonthlyFee = 'batch14MonthlyFee',
    batch14BtCashBack = 'batch14BtCashBack',
    batch15CashBackRedemption = 'batch15CashBackRedemption',
    batch16CashBackRedemption = 'batch16CashBackRedemption',
    batch17CashBackRedemption = 'batch17CashBackRedemption',
    batch18CashBackRedemption = 'batch18CashBackRedemption',
    batch16NewHomeowner = 'batch16NewHomeowner',
    moapV1 = 'moapV1',
    moapV1NoBtFee = 'moapV1NoBtFee',
    moapV2 = 'moapV2',
    preJuly2021Default = 'preJuly2021Default',
    postJuly2021Default = 'postJuly2021Default',
    default20210802 = 'default20210802',
    postNov2021 = 'postNov2021',
    postNov2021NoThirdLiens = 'postNov2021NoThirdLiens',
    postNov2021NoThirdLiensDirectMail = 'postNov2021NoThirdLiensDirectMail',
    postMay2022 = 'postMay2022',
    postMay2022FFTRUL = 'postMay2022FFTRUL',
    simpleAPRTable = 'simpleAPRTable',
    testimonials = 'testimonials',
    bankrate = 'bankrate',
    debtV1 = 'debtV1',
    start = 'start',

    // Aven internal + auxiliary staff
    // CAUTION: You can ONLY assign a NoLoyalty experiment to an account that has been created on a NoLoyalty product code on Core Card.
    // Same with Loyalty. If you want to switch between Loyalty and No Loyalty products, a product transfer on Core Card is
    // necessary. Merely changing the experiment name won't automatically trigger product transfer and there is a chance that the account
    // malfunctions.
    internalHomeNoLoyalty = 'internalHomeNoLoyalty',
    internalHomeLoyalty = 'internalHomeLoyalty',
}
