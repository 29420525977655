import { RouteConfig } from 'vue-router'

const routeName = 'express'

const pageNames = {
    PERSONAL_INFO: 'personal_info',
    STATED_INCOME: 'stated_income',
    PRE_QUALIFICATION: 'pre_qualification',
}

export const mailerOriginationPagePaths = {
    PERSONAL_INFO: `/${routeName}/${pageNames.PERSONAL_INFO}`,
    STATED_INCOME: `/${routeName}/${pageNames.STATED_INCOME}`,
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
}

export const mailerOriginationRoutes: RouteConfig[] = [
    {
        path: mailerOriginationPagePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/mailerOrigination/StatedIncome.vue'),
    },
    {
        path: mailerOriginationPagePaths.PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/mailerOrigination/PersonalInfo.vue'),
    },
    {
        path: mailerOriginationPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/mailerOrigination/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
]
