// Could be a unit test
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath } from '@/flow/flowUtility'
import { originationPagePaths } from '@/routes/originationRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { authPagePaths } from '@/routes/authRoutes'

export const hmdaEntryPaths: string[] = [authPagePaths.HMDA_FLOW]

export const hmdaReturnPagePathPairs: string[][] = []

export const hmdaFlow: string[] = [originationPagePaths.HMDA, `${sharedPagePaths.THANKS}?reason=hmdaFinished`]

export const hmdaBackGuardPagePaths: string[] = []

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map()

if (!checkAreAllPathsUnique(hmdaFlow, optionalFlowMap)) {
    throw new Error('hmda page paths not unique!')
}

export const hmdaNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    const noParamsEntryPath = hmdaEntryPaths[0].replace(/:\w+/, '')
    if (currentPath.startsWith(noParamsEntryPath)) {
        return hmdaFlow[0]
    }

    const nextPath = tryGetNextFlowPath(currentPath, hmdaFlow, optionalFlowMap)
    return nextPath
}
