import { HeraclesParameter } from '../heraclesParameter'
import { ExperimentName } from '@/experiments/experimentName'
import { experimentPageBasePaths, experimentPageJoinPaths } from '@/experiments/src/routes/marketingRoutes'

export class Batch14OriginationFeePolicy extends HeraclesParameter {
    EXPERIMENT_NAME = ExperimentName.batch14OriginationFee
    EXPERIMENT_BASE_PATH = experimentPageBasePaths.GO_AVEN
    JOIN_PATH = experimentPageJoinPaths.GO_AVEN_JOIN
    ORIGINATION_FEE = 99.0
}
