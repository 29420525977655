// Duplicate constant warning! Keep in sync with supportedStatesManager.types.ts until we can fix this
import { isStateActive } from '@/services/api'
import { logger } from '@/utils/logger'

// From: https://www.faa.gov/air_traffic/publications/atpubs/cnt_html/appendix_a.html
export const supportedStates = [
    { fullName: 'ALABAMA', twoLetterCode: 'AL' },
    { fullName: 'ALASKA', twoLetterCode: 'AK' },
    { fullName: 'AMERICAN SAMOA', twoLetterCode: 'AS' },
    { fullName: 'ARIZONA', twoLetterCode: 'AZ' },
    { fullName: 'ARKANSAS', twoLetterCode: 'AR' },
    { fullName: 'CALIFORNIA', twoLetterCode: 'CA' },
    { fullName: 'COLORADO', twoLetterCode: 'CO' },
    { fullName: 'CONNECTICUT', twoLetterCode: 'CT' },
    { fullName: 'DELAWARE', twoLetterCode: 'DE' },
    { fullName: 'DISTRICT OF COLUMBIA', twoLetterCode: 'DC' },
    { fullName: 'FLORIDA', twoLetterCode: 'FL' },
    { fullName: 'GEORGIA', twoLetterCode: 'GA' },
    { fullName: 'GUAM', twoLetterCode: 'GU' },
    { fullName: 'HAWAII', twoLetterCode: 'HI' },
    { fullName: 'IDAHO', twoLetterCode: 'ID' },
    { fullName: 'ILLINOIS', twoLetterCode: 'IL' },
    { fullName: 'INDIANA', twoLetterCode: 'IN' },
    { fullName: 'IOWA', twoLetterCode: 'IA' },
    { fullName: 'KANSAS', twoLetterCode: 'KS' },
    { fullName: 'KENTUCKY', twoLetterCode: 'KY' },
    { fullName: 'LOUISIANA', twoLetterCode: 'LA' },
    { fullName: 'MAINE', twoLetterCode: 'ME' },
    { fullName: 'MARYLAND', twoLetterCode: 'MD' },
    { fullName: 'MASSACHUSETTS', twoLetterCode: 'MA' },
    { fullName: 'MICHIGAN', twoLetterCode: 'MI' },
    { fullName: 'MINNESOTA', twoLetterCode: 'MN' },
    { fullName: 'MISSISSIPPI', twoLetterCode: 'MS' },
    { fullName: 'MISSOURI', twoLetterCode: 'MO' },
    { fullName: 'MONTANA', twoLetterCode: 'MT' },
    { fullName: 'NEBRASKA', twoLetterCode: 'NE' },
    { fullName: 'NEVADA', twoLetterCode: 'NV' },
    { fullName: 'NEW HAMPSHIRE', twoLetterCode: 'NH' },
    { fullName: 'NEW JERSEY', twoLetterCode: 'NJ' },
    { fullName: 'NEW MEXICO', twoLetterCode: 'NM' },
    { fullName: 'NEW YORK', twoLetterCode: 'NY' },
    { fullName: 'NORTH CAROLINA', twoLetterCode: 'NC' },
    { fullName: 'NORTH DAKOTA', twoLetterCode: 'ND' },
    { fullName: 'NORTHERN MARIANA IS', twoLetterCode: 'MP' },
    { fullName: 'OHIO', twoLetterCode: 'OH' },
    { fullName: 'OKLAHOMA', twoLetterCode: 'OK' },
    { fullName: 'OREGON', twoLetterCode: 'OR' },
    { fullName: 'PENNSYLVANIA', twoLetterCode: 'PA' },
    { fullName: 'PUERTO RICO', twoLetterCode: 'PR' },
    { fullName: 'RHODE ISLAND', twoLetterCode: 'RI' },
    { fullName: 'SOUTH CAROLINA', twoLetterCode: 'SC' },
    { fullName: 'SOUTH DAKOTA', twoLetterCode: 'SD' },
    { fullName: 'TENNESSEE', twoLetterCode: 'TN' },
    { fullName: 'TEXAS', twoLetterCode: 'TX' },
    { fullName: 'UTAH', twoLetterCode: 'UT' },
    { fullName: 'VERMONT', twoLetterCode: 'VT' },
    { fullName: 'VIRGINIA', twoLetterCode: 'VA' },
    { fullName: 'VIRGIN ISLANDS', twoLetterCode: 'VI' },
    { fullName: 'WASHINGTON', twoLetterCode: 'WA' },
    { fullName: 'WEST VIRGINIA', twoLetterCode: 'WV' },
    { fullName: 'WISCONSIN', twoLetterCode: 'WI' },
    { fullName: 'WYOMING', twoLetterCode: 'WY' },
] as const

const getSupportedState = (state: string): typeof supportedStates[number] | undefined => {
    const uppercaseState = state.trim().toUpperCase()
    return supportedStates.find((supportedState) => {
        return uppercaseState === supportedState.fullName || uppercaseState === supportedState.twoLetterCode
    })
}

export const isSupportedState = async (state: string, streetAddress?: string): Promise<boolean> => {
    const supportedState = getSupportedState(state)

    if (supportedState) {
        const stateSupportedResponse = await isStateActive(supportedState.twoLetterCode, streetAddress)
        return stateSupportedResponse.data.payload.isStateActive
    } else {
        // Every US state is in the list, we should never reach this
        logger.error(`Can't find the state of ${state}`)
    }

    return false
}
