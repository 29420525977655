import { RouteConfig } from 'vue-router'

const routeName = 'origination'

const pageNames = {
    CONFIRM_DOB: 'confirm_dob',
    STATED_USAGE: 'stated_usage',
    PERSONAL_INFO: 'personal_info',
    STATED_INCOME: 'stated_income',
    SECONDARY_SIGNERS: 'secondary_signers',
    DISPUTE_CO_OWNER_INFO: 'dispute_co_owner_info',
    EMPLOYER_EMAIL: 'employer_email',
    EMPLOYER_EMAIL_CODE_VERIFICATION: 'code_verification',
    EMPLOYER_EMAIL_PURGATORY: 'employer_email_verify',
    HMDA: 'hmda',
    OFFER_PREVIEW: 'offer_preview',
    PRE_QUALIFICATION: 'pre_qualification',
    VERIFY_IDENTITY: 'identity',
    CO_APPLICANT_VERIFY_IDENTITY: 'co_applicant_identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    CO_APPLICANT_IDENTITY_QUESTIONS: 'co_applicant_identity_questions',
    PROPERTY_VERIFICATION: 'property_verification',
    EMPLOYMENT_INCOME_VERIFICATION: 'employment_income_verification',
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: 'co_applicant_employment_income_verification',
    PAY_STUB_VERIFICATION: 'verify_pay_stubs',
    TAX_RETURN_VERIFICATION: 'verify_tax_return',
    OTHER_INCOME_VERIFICATION: 'verify_other_income',
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: 'awaiting_manual_document_verification',
    FLOOD_INSURANCE_VERIFICATION: 'flood_insurance_verification',
    REMEDY_NAME_MISMATCH: 'remedy_name_mismatch',
    SELECT_INCOME_VERIFICATION_METHOD: 'select_income_verification_method',
    PROVIDE_SUPPORTING_INFORMATION: 'supporting_information',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    CO_APPLICANT_EXPERIAN_FROZEN: 'co_applicant_experian_frozen',
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: 'offer_add_co_applicant_on_failure',
    ADD_CO_APPLICANT_INFO: 'add_co_applicant_information',
    SSN_VERIFICATION: 'ssn_verification',
    CO_APPLICANT_SSN_VERIFICATION: 'co_applicant_ssn_verification',
    TRUST_INFO_SUBMISSION: 'trust_info_submission',
    FORWARD_TO_NOTARIZATION: 'forward_to_notarization',
    SIGN_TOGETHER: 'sign_together',
}

export const originationPagePaths = {
    CONFIRM_DOB: `/${routeName}/${pageNames.CONFIRM_DOB}`,
    STATED_USAGE: `/${routeName}/${pageNames.STATED_USAGE}`,
    PERSONAL_INFO: `/${routeName}/${pageNames.PERSONAL_INFO}`,
    EMPLOYER_EMAIL: `/${routeName}/${pageNames.EMPLOYER_EMAIL}`,
    EMPLOYER_EMAIL_CODE_VERIFICATION: `/${routeName}/${pageNames.EMPLOYER_EMAIL_CODE_VERIFICATION}`,
    EMPLOYER_EMAIL_PURGATORY: `/${routeName}/${pageNames.EMPLOYER_EMAIL_PURGATORY}`,
    STATED_INCOME: `/${routeName}/${pageNames.STATED_INCOME}`,
    SECONDARY_SIGNERS: `/${routeName}/${pageNames.SECONDARY_SIGNERS}`,
    DISPUTE_CO_OWNER_INFO: `/${routeName}/${pageNames.DISPUTE_CO_OWNER_INFO}`,
    HMDA: `/${routeName}/${pageNames.HMDA}`,
    OFFER_PREVIEW: `/${routeName}/${pageNames.OFFER_PREVIEW}`,
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
    VERIFY_IDENTITY: `/${routeName}/${pageNames.VERIFY_IDENTITY}`,
    CO_APPLICANT_VERIFY_IDENTITY: `/${routeName}/${pageNames.CO_APPLICANT_VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${pageNames.IDENTITY_QUESTIONS}`,
    CO_APPLICANT_IDENTITY_QUESTIONS: `/${routeName}/${pageNames.CO_APPLICANT_IDENTITY_QUESTIONS}`,
    PROPERTY_VERIFICATION: `/${routeName}/${pageNames.PROPERTY_VERIFICATION}`,
    EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${pageNames.EMPLOYMENT_INCOME_VERIFICATION}`,
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${pageNames.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION}`,
    PAY_STUB_VERIFICATION: `/${routeName}/${pageNames.PAY_STUB_VERIFICATION}`,
    TAX_RETURN_VERIFICATION: `/${routeName}/${pageNames.TAX_RETURN_VERIFICATION}`,
    OTHER_INCOME_VERIFICATION: `/${routeName}/${pageNames.OTHER_INCOME_VERIFICATION}`,
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: `/${routeName}/${pageNames.AWAITING_MANUAL_DOCUMENT_VERIFICATION}`,
    FLOOD_INSURANCE_VERIFICATION: `/${routeName}/${pageNames.FLOOD_INSURANCE_VERIFICATION}`,
    REMEDY_NAME_MISMATCH: `/${routeName}/${pageNames.REMEDY_NAME_MISMATCH}`,
    SELECT_INCOME_VERIFICATION_METHOD: `/${routeName}/${pageNames.SELECT_INCOME_VERIFICATION_METHOD}`,
    PROVIDE_SUPPORTING_INFORMATION: `/${routeName}/${pageNames.PROVIDE_SUPPORTING_INFORMATION}`,
    EXPERIAN_FROZEN: `/${routeName}/${pageNames.EXPERIAN_FROZEN}`,
    CO_APPLICANT_EXPERIAN_FROZEN: `/${routeName}/${pageNames.CO_APPLICANT_EXPERIAN_FROZEN}`,
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: `/${routeName}/${pageNames.OFFER_ADD_CO_APPLICANT_ON_FAILURE}`,
    ADD_CO_APPLICANT_INFO: `/${routeName}/${pageNames.ADD_CO_APPLICANT_INFO}`,
    SSN_VERIFICATION: `/${routeName}/${pageNames.SSN_VERIFICATION}`,
    CO_APPLICANT_SSN_VERIFICATION: `/${routeName}/${pageNames.CO_APPLICANT_SSN_VERIFICATION}`,
    TRUST_INFO_SUBMISSION: `/${routeName}/${pageNames.TRUST_INFO_SUBMISSION}`,
    FORWARD_TO_NOTARIZATION: `/${routeName}/${pageNames.FORWARD_TO_NOTARIZATION}`,
    SIGN_TOGETHER: `/${routeName}/${pageNames.SIGN_TOGETHER}`,
}

const dataCollectionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.STATED_USAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedUsage.vue'),
        meta: { public: true },
    },
    {
        path: originationPagePaths.PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PersonalInfo.vue'),
    },
    {
        path: originationPagePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE,
        component: () => import('@/pages/origination/OfferAddCoApplicantOnFailure.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.ADD_CO_APPLICANT_INFO,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/AddCoApplicantInfo.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "coOrigination" */ '@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmployerEmail.vue'),
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL_CODE_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmployerEmailCodeVerification.vue'),
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL_PURGATORY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/EmployerEmailPurgatory.vue'),
    },
    {
        path: originationPagePaths.PAY_STUB_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PayStubVerification.vue'),
    },
    {
        path: originationPagePaths.TAX_RETURN_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TaxReturnVerification.vue'),
    },
    {
        path: originationPagePaths.OTHER_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OtherIncomeStatementUpload.vue'),
    },
    {
        path: originationPagePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedIncome.vue'),
    },
    {
        path: originationPagePaths.EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ExperianFrozen.vue'),
    },
    {
        path: originationPagePaths.HMDA,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/HMDA.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SECONDARY_SIGNERS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SecondarySigners.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.DISPUTE_CO_OWNER_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/DisputeCoOwnerInfo.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SIGN_TOGETHER,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SignTogether.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
    },
    {
        path: originationPagePaths.IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
    },
    {
        path: originationPagePaths.PROPERTY_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PropertyVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SELECT_INCOME_VERIFICATION_METHOD,
        redirect: (to) => {
            return { path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION }
        },
    },
    {
        path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
]

const completionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.PROVIDE_SUPPORTING_INFORMATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ProvideSupportingInformation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/AwaitingManualDocumentVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REMEDY_NAME_MISMATCH,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/RemedyNameMismatch.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.TRUST_INFO_SUBMISSION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TrustInfoSubmission.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.FORWARD_TO_NOTARIZATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ForwardToNotarization.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.FLOOD_INSURANCE_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/FloodInsuranceVerification.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const originationRoutes: RouteConfig[] = [...dataCollectionRoutes, ...completionRoutes]
