import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { httpClient, loggerHttpClient } from '@/utils/http-client'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { currentContextForLogging } from '@/main'
import isEmpty from 'lodash/isEmpty'
import utilInspect from 'util-inspect'
import router from '@/routes/router'

export const LogLevel = {
    // mirrors the levels used on the backend
    debug: 'DEBUG',
    log: 'INFO',
    info: 'INFO',
    warn: 'WARN',
    error: 'FATAL',
    fatal: 'FATAL',
}

/**
 * We use this as the error that we send to Sentry when our code only sends an error message,
 * but not a thrown Error (or Error subclass). For example:
 * logger.error('some message without an error')
 * As opposed to:
 * logger.error('some message with an error', error)
 *
 * Without this message wrapper, we can't actually group different Sentry messages because Sentry
 * automatically tries to group by stacktrace, which is not appropriate for error messages without
 * thrown errors.
 */
class SentryMessage extends Error {
    constructor(msg: string) {
        super(msg)
        this.name = 'SentryMessage'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

const inspect = (msg: Object) => {
    return utilInspect(msg, { breakLength: Infinity })?.replace(/[\s]*[\n][\s]*/g, '; ')
}

// these are error messages we want to demote to 'warn' severity instead of 'exception' severity
const demotedErrorMessages = [
    /Network Error/gi, // A temporary network problem that is /usually/ the on client's side / mobile networks / etc
    /Unexpected token/gi, // This is caused when we deploy while someone is on the site and they get a 404 from the missing file
    /SyntaxError/gi, // This pops up on offbrand browsers, esp ones that inject their own JS
    /Loading.*chunk.*failed./gi, // This is also caused by a bad client-side cache and/or deploy
    /Request aborted/gi, // This is caused when an in-flight HTTP request is cancelled (while reloading, changing pages, etc)
    /Script error\./gi, // This is caused when a script loaded from a third-party fails for any reason. Browser does not provide any details even about the third-party origin (see: https://sentry.io/answers/script-error/)
    /still_here/gi, // Just a noisy lil boy
    /Request failed with status code 401/gi, // private browsing etc.
]

const ignoreErrors = [
    /(?=.*?(Max retry limit of 2 exceeded with error))(?=.*?(still_here))/gi, // This is a noisy sentry for when we retry sending the 'still_here' event which pings our backend frequently
]

const Logger = class {
    isDevelopment = process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes'
    isNetworkLoggingEnabled = true

    setNetworkLogging = (isEnabled: boolean) => {
        console.log('Setting network logging to: ' + isEnabled)
        this.isNetworkLoggingEnabled = isEnabled
    }

    debug = (message: string) => {
        if (this.isDevelopment) {
            console.debug(message)
        }
        this.trySendToBackend({ message, level: LogLevel.debug })
    }

    log = (message: string) => {
        if (this.isDevelopment) {
            console.log(message)
        }
        this.trySendToBackend({ message, level: LogLevel.log })
    }

    info = (message: string) => {
        if (this.isDevelopment) {
            console.info(message)
        }
        this.trySendToBackend({ message, level: LogLevel.info })
    }

    warn = (message: string, error?: any, event?: Event | PromiseRejectionEvent | string) => {
        if (this.isDevelopment) {
            console.warn(message, error, event)
        }
        this.trySendToBackend({ message, event, level: LogLevel.warn, error })
    }

    error = (message: string, error?: any, event?: Event | PromiseRejectionEvent | string) => {
        try {
            message = this.formatMessage(message)
            if (!message) {
                return // Don't send empty messages
            }

            // Redirect network errors to warn() so we don't trigger a high-severity Sentry and
            // page the on-call engineer
            if (message?.includes('Network Error')) {
                this.warn(message, error, event)
                return
            }

            if (this.isDevelopment) {
                console.error(message, event, error)
            }
        } catch (e) {
            message = `NOTE: Failed to pre-process logger.error message due to error ${e}! ` + message
        }

        this.trySendToBackend({ message, event, level: LogLevel.error, error })
    }

    // creates the same message we would have generated if sent to the backend
    createBackendLogPayload = (message: string, level: string) => {
        const payload: { log: string }[] = []
        const prefix = this.getUserIdentities()
        for (const line of message.split(/[\n\r]+/)) {
            payload.push({
                log: `${level}: [EXT@aven.com] > ${prefix} ${line}`,
            })
        }
        return payload
    }

    logMessageToBackend = async (message: string, level = 'INFO') => {
        // if these match we're sending to the backend, if not we're sending to a different endpoint for logs
        if (process.env.VUE_APP_LOGGER_BASE_URL === process.env.VUE_APP_API_BASE_URL) {
            const postBody = {
                message,
                level,
            }
            await httpClient.post('/logs', postBody)
        } else {
            const postBody = this.createBackendLogPayload(message, level)
            await loggerHttpClient.post('/aven.log', postBody)
        }
    }

    trySendToBackend = async ({ message, level, error, event }: { message: string; event?: Event | PromiseRejectionEvent | string; level?: string; error?: any }) => {
        message = this.formatMessage(message)
        if (!message || !this.isNetworkLoggingEnabled) {
            return // Don't send empty messages
        }

        try {
            if (typeof event === 'string') {
                message += `\tEvent: ${event}`
            } else if (typeof event !== 'undefined') {
                message += `\tEventJSON: ${JSON.stringify(event)}`
            }
        } catch (e) {
            message = `NOTE: Failed to append some items to message due to error ${e}! ` + message
        }

        try {
            await this.logMessageToBackend(message, level)

            if (level === LogLevel.error || level === LogLevel.warn) {
                // Note that LogLevel.Error maps to Sentry.Severity.Fatal purposefully because
                // our frontend's highest LogLevel is Error, while Sentry's is Fatal
                const severity: Sentry.Severity = level === LogLevel.error ? Sentry.Severity.Fatal : Sentry.Severity.Error
                this.logMessageToSentry(message, severity, error)
            } else {
                let severity
                if (level === LogLevel.info) {
                    severity = Sentry.Severity.Info
                } else if (level === LogLevel.log) {
                    severity = Sentry.Severity.Log
                } else {
                    severity = Sentry.Severity.Debug
                }
                this.addLogBreadcrumbToSentry(severity, message)
            }
        } catch (error) {
            if (this.isDevelopment) {
                console.error(`Could not log message to backend due to error: ${inspect(error as Object)}\nThe message was ${message}`)
            }
            this.logMessageToSentry(`Could not log message to backend: ${message}`, Sentry.Severity.Error, error, { isPossibleNetworkIssue: true })
        }
    }

    /**
     * Breadcrumbs are used to create a trail of events prior to an issue.
     * https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/
     */
    addLogBreadcrumbToSentry = (severity: Sentry.Severity, message: string) => {
        Sentry.addBreadcrumb({
            // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
            type: 'info',
            level: severity,
            // We need to truncate breadcrumb messages because Sentry will throw a 413 Payload too large
            // error in certain instances since we create very large log messages. NOTE THAT THIS IS NOT
            // TANTAMOUNT TO TRUNCATING LOGS! The logs are still fully available in CloudWatch.
            message: message?.slice(0, 256),
        })
    }

    logMessageToSentry(message: string, severity: Sentry.Severity, error?: any, tags?: Object) {
        try {
            const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)

            const captureContext: any = {
                level: severity ?? Sentry.Severity.Fatal,
                extra: {
                    message,
                    logRocketUrl: appSessionStorage.getItem(sessionStorageKey.logRocketUrl) || 'not_set',
                    identities: this.getUserIdentities(),
                    contextData: this.getContextDataInfo(),
                    // @ts-ignore navigator connection hasn't been standardized yet.
                    networkQuality: `type: ${navigator?.connection?.effectiveType}, downlink: ${navigator?.connection?.downlink}, online: ${navigator?.onLine}`,
                },
                tags: {
                    noSessionId: !sessionId,
                    sessionId,
                    applicantId: appSessionStorage.getItem(sessionStorageKey.applicantId),
                    loanApplicationId: appSessionStorage.getItem(sessionStorageKey.loanApplicationId),
                    logRocketUrl: appSessionStorage.getItem(sessionStorageKey.logRocketUrl) || 'not_set',
                    ...tags,
                },
            }
            const errorToSend = error ?? new SentryMessage(message)
            Sentry.captureException(errorToSend, captureContext)
        } catch (e) {
            Sentry.captureException(new Error(`Failed to capture exception due to error: ${e}\nException was ${message}`))
        }
    }

    formatMessage = (message: string) => {
        if (!message) {
            return ''
        }
        if (typeof message === 'object') {
            if (isEmpty(message)) {
                return ''
            }
            return inspect(message)
        }

        return message.trim()
    }

    getContextDataInfo = () => {
        let dataInfo = 'COMPONENT DATA:\n'
        try {
            for (const [contextName, context] of Object.entries(currentContextForLogging)) {
                const contextData = (context as any)._data
                const data = Object.assign({}, contextData)
                const info = inspect(data)
                if (Object.keys(data).length !== 0 && !info.includes('[Getter/Setter]')) {
                    // don't include components with no data and don't include built-in components like observers that have getters/setters
                    dataInfo += `Data of ${contextName}: ${inspect(data)}\n\n`
                }
            }

            dataInfo += `SESSION STORAGE:\n${JSON.stringify(appSessionStorage.getAll())}`
        } catch (e) {
            dataInfo += `\nFailed to log additional error data due to error: ${e}`
        }
        return dataInfo
    }

    getUserIdentities = () => {
        const applicantId = appSessionStorage.getItem(sessionStorageKey.applicantId)
        const loanApplicationId = appSessionStorage.getItem(sessionStorageKey.loanApplicationId)
        const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)

        const identityArray = []
        if (sessionId) {
            identityArray.push(`S: ${sessionId}`)
        }
        if (applicantId) {
            identityArray.push(`A: ${applicantId}`)
        }
        if (loanApplicationId) {
            identityArray.push(`L: ${loanApplicationId}`)
        }

        let identities = 'None'
        if (identityArray.length > 0) {
            identities = '[' + identityArray.join(' | ') + ']'
        }
        return identities
    }

    constructor() {
        const isScraper = /google|fbid|facebook|fbav|fb_/gi.test(navigator.userAgent)
        const isLocalDev = process.env.VUE_APP_NODE_ENV === 'development'
        // Strip http/https from URLs
        const allowUrls = [process.env.VUE_APP_API_BASE_URL, process.env.VUE_APP_AVEN_URL, process.env.VUE_APP_LAMBDA_BASE_URL].map((x) => x.replace(/^https?:\/\//gi, ''))
        if (!isLocalDev && !isScraper) {
            console.info('[INFO] Allowing logs to go to sentry')
            Sentry.init({
                dsn: process.env.VUE_APP_SENTRY_ID,
                environment: process.env.VUE_APP_NODE_ENV,
                release: process.env.VUE_APP_SENTRY_RELEASE,
                allowUrls: !this.isDevelopment ? allowUrls : undefined,
                maxValueLength: 1024 * 8,
                attachStacktrace: true,
                defaultIntegrations: false,
                ignoreErrors,
                integrations: [
                    new TracingIntegrations.BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        // only attach the tracing id to the backend api url
                        tracingOrigins: [process.env.VUE_APP_API_BASE_URL.replace(/^https?:\/\//gi, '')],
                    }),
                    new Sentry.Integrations.FunctionToString(),
                    new Sentry.Integrations.InboundFilters(),
                    new Sentry.Integrations.Breadcrumbs(),
                    new Sentry.Integrations.LinkedErrors(),
                    new Sentry.Integrations.UserAgent(),
                ],
                // this determines what percentage of requests we want to trace
                tracesSampleRate: process.env.VUE_APP_NODE_ENV === 'production' ? 0.3 : 1.0,
                initialScope: {
                    tags: {
                        // add any global static tags here
                        avenProject: 'origination',
                    },
                },
            })
        } else {
            console.info('[INFO] Refusing to log development and/or Googlebot to sentry')
        }

        // eslint-disable-next-line no-unused-vars
        Vue.config.errorHandler = (err, vm, info) => {
            // Can't serialize 'vm', which is a recursively defined Vue object (also no point)
            // 'info' isn't that useful, only has a short string description
            // err is the actual stack trace
            const msg = `vue error: ${err}\t${err.stack}`
            if (demotedErrorMessages.some((regex) => regex.test(String(err.message)))) {
                this.warn(msg, err)
            } else {
                this.error(msg, err)
            }
        }

        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
            if (/Loading.*chunk.*failed./i.test(event.reason.message || '')) {
                this.info('Reloading page to fix stale chunk error')
                this.logMessageToSentry(`window.onunhandledrejection error: Reloading page to fix stale chunk error: ${inspect(event.reason)}`, Sentry.Severity.Warning, null, {
                    isPossibleNetworkIssue: true,
                })
                return window.location.reload()
            }

            const reason = event.reason
            const msg = `window.onunhandledrejection error: ${inspect(reason)}`
            if (demotedErrorMessages.some((regex) => regex.test(String(reason)))) {
                this.warn(msg, reason instanceof Error ? reason : undefined, event)
            } else {
                this.error(msg, reason instanceof Error ? reason : undefined, event)
            }
        }

        window.onerror = (message, source, lineno, colno, error) => {
            const msg = `window.onerror error: ${inspect({ message, source, lineno, colno, error })}`
            if (demotedErrorMessages.some((regex) => regex.test(String(message)))) {
                this.warn(msg, error, message)
            } else {
                this.error(msg, error, message)
            }
        }
    }
}

const logger = new Logger()

export { logger, inspect }
