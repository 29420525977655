export const isSafariPrivateBrowsing = () => {
    try {
        localStorage.setItem('safari', 'safar1i')
        localStorage.removeItem('safari')
        // logger.log('Did not find private browsing')
        return false
    } catch (e) {
        // logger.log('Found safari private browsing')
        return true
    }
}

export function isIOS() {
    return !!window.navigator.userAgent.match(/(iPad|iPhone|iPod)/)
}

export const isIOSSafari = () => {
    return (
        'standalone' in window.navigator && // There's a thing called standalone in nav
        // @ts-ignore
        !window.navigator.standalone && // It is not running in standalone mode
        // @ts-ignore
        window.navigator.userAgent.indexOf('i') != -1 && // iPhone is in the UA string (could be Opera)
        // @ts-ignore
        window.navigator.userAgent.indexOf('Mac OS') != -1 && // There's Mac in the UA string (not Opera)
        // @ts-ignore
        window.navigator.userAgent.indexOf('Safari') != -1
    )
    /* if all the above are true this probably means this is
        the Safari browser,
        not a webview in an app,
        not a page in standalone mode */
}

export const isIOSChromeFF = () => {
    // From: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
    return !!window.navigator.userAgent.match(/(CriOS|FxiOS)/)
}

export const isAndroid = () => {
    return !!window.navigator.userAgent.match(/Android/)
}

export const isAndroidBrowserOrChrome = () => {
    // From: https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
    const isWebview = !!window.navigator.userAgent.match(/\Wwv\W/)
    if (isWebview) {
        return false
    }

    return !!window.navigator.userAgent.match('Mobile Safari/')
}
