import { HeraclesParameter } from '@/experiments/heraclesParameter'
import { ExperimentName } from '@/experiments/experimentName'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'

export class MoapV2Policy extends HeraclesParameter {
    EXPERIMENT_NAME = ExperimentName.moapV2
    EXPERIMENT_BASE_PATH = experimentPagePaths.AVEN_FINANCE
    JOIN_PATH = experimentPagePaths.AVEN_FINANCE_JOIN
    BALANCE_TRANSFER_FEE_PERCENT = 0.02
}
