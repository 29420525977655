import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

const getMetadata = () => {
    return {
        resolution: window.innerWidth + 'x' + window.innerHeight,
        cookies: document.cookie,
        // Pass raw query string to allow backend to parse however it likes
        queryParams: window.location.search,
        path: window.location.pathname,
        avenProperty: 'ORIGINATION',
        referrer: document.referrer,
        referringDomain: document.referrer ? new URL(document.referrer).hostname : undefined,
    }
}

export const fireSessionIdRequest = (): XMLHttpRequest => {
    const sessionIdReadyEvent = new Event('sessionIdReady')

    const req = new XMLHttpRequest()
    req.onreadystatechange = function () {
        // When request ready + successful
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const resp = JSON.parse(this.responseText)
            console.log('Setting session details:', resp.payload)
            appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, resp.payload.jwt.sessionAccessJWT)
            appSessionStorage.setItem(sessionStorageKey.sessionId, resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.experimentName, resp.payload.experimentName)
            appSessionStorage.setItem(sessionStorageKey.experimentOverrides, JSON.stringify(resp.payload.experimentOverrides || {}))
            window.dispatchEvent(sessionIdReadyEvent)
        } else if (this.readyState === XMLHttpRequest.DONE) {
            throw new Error('getSessionId call failed with status ' + this.status)
        }
    }
    req.open('POST', `${process.env.VUE_APP_API_BASE_URL}/ana/session`)
    req.setRequestHeader('Content-Type', 'application/json')
    console.log('Requesting sessionId + experimentName, also sending metadata')
    const body = JSON.stringify(getMetadata())
    setTimeout(function () {
        req.send(body)
    }, 100)

    return req
}

const invalidValues = ['undefined', 'null', '[object Object]']
const existsAndValid = (value: string | null | undefined) => {
    return value && !invalidValues.includes(value)
}

export const isSessionDataPresent = () => {
    return (
        existsAndValid(appSessionStorage.getItem(sessionStorageKey.sessionId)) &&
        existsAndValid(appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)) &&
        existsAndValid(appSessionStorage.getItem(sessionStorageKey.experimentName))
    )
    // "undefined" is returned from appSessionStorage as a string if undefined was placed into it
}
