import { coApplicantHttpClient, httpClient } from '@/utils/http-client'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { maybeInitFacebook, logRocketIdentify } from '@/services/marketing'
import { ParentApplicantRelationshipType } from '@/services/homeApi'

const postRoute = async (route: string, postBody: string | object, isCoApplicant: boolean = false) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(route, postBody)
    }
    return await httpClient.post(route, postBody)
}

const getApplicantReturning = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturning', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    logRocketIdentify()
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const getApplicantReturningToDocumentUploadPortal = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturningToDocumentUploadPortal', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    logRocketIdentify()
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const getApplicantReturningHMDA = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturningHMDA', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    logRocketIdentify()
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const saveApplicantSessionResponse = (response: { data: any }) => {
    const data = response.data
    const payload = data?.payload
    appSessionStorage.setItemIfPresent(sessionStorageKey.loanApplicationId, payload?.loanApplicationId, (id) => id.toString())
    appSessionStorage.setItemIfPresent(sessionStorageKey.phoneNumber, payload?.phoneNumber)

    if (payload && payload.jwt) {
        logger.info(`Saving applicant session response - applicant id: ${payload.applicantId} / loan application id: ${payload.loanApplicationId}`)

        appSessionStorage.setItemIfPresent(sessionStorageKey.jwtTokens, payload.jwt, (jwt) => JSON.stringify(jwt))
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantJwtTokens, payload.coApplicantJwtTokens, (jwt) => JSON.stringify(jwt))

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantId, payload.applicantId, (id) => id.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.experimentName, payload.experimentName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.inviteCode, payload.inviteCode)

        if (payload.pifBonus) {
            appSessionStorage.setItem(sessionStorageKey.pifBonus, String(payload.pifBonus))
        }

        appSessionStorage.setItemIfPresent(sessionStorageKey.pifSenderName, payload.pifSenderName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.phoneNumber, payload.phoneNumber)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantPhoneNumber, payload.coApplicantPhoneNumber)

        appSessionStorage.setItemIfPresent(sessionStorageKey.firstName, payload.firstName)
        appSessionStorage.setItemIfPresent(sessionStorageKey.lastName, payload.lastName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantFirstName, payload.coApplicantFirstName)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantLastName, payload.coApplicantLastName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.statedIncome, payload.statedIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantStatedIncome, payload.coApplicantStatedIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantMaritalStatus, payload.maritalStatus)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantMaritalStatus, payload.coApplicantMaritalStatus)

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantSubmittedEmployer, payload.hasSubmittedEmployer, (val) => val.toString())
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantSubmittedEmployer, payload.hasCoApplicantSubmittedEmployer, (val) => val.toString())
    }
}

interface AbandonApplicationParams {
    wantsToAbandonCurrentApplication: boolean
    returnToken2ForPriorApplication: string
    isCoApplicant: boolean
}
const abandonApplication = async ({ wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant }: AbandonApplicationParams) => {
    return await httpClient.post('/origination/abandonApplication', { wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant })
}

const getNextHomeApplicationAction = async () => {
    return await httpClient.get('/nextApplicationAction')
}

const plaidReportFetchState = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/plaidReportFetchState')
    }
    return await httpClient.get('/plaidReportFetchState')
}

const getLegalDocument = async (docType: LegalDocumentTypes) => {
    return await httpClient.get('/legal', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

const getHtmlLegalDocument = async (docType: LegalDocumentTypes) => {
    return await httpClient.get('/legalHtml', {
        params: {
            docType,
        },
    })
}

const getLegalDocumentForSession = async (docType: LegalDocumentTypes) => {
    return await httpClient.get('/legal/documentForSession', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

// Keep in sync with consts in aven_backend/src/manager/LegalDocumentManager.ts
enum LegalDocumentTypes {
    hudGovDoc = 'HomeOwnershipCounselingServices',
    creditScoreDisclosure = 'CreditScoreDisclosure',
    shortFormDeedOfTrust = 'ShortFormDeedOfTrust',
    longFormDeedOfTrust = 'LongFormDeedOfTrust',
    certificationOfTrust = 'CertificationOfTrust',
    helocDeedOfTrust = 'HelocDeedOfTrust',
    lastTransferDocument = 'LastTransferDocument',
    accountAgreement = 'AccountAgreement',
    noticeOfRightToCancel = 'NoticeOfRightToCancel',
    adverseActionNotice = 'AdverseActionNotice',
    fictitiousDeedOfTrust = 'FictitiousDeedOfTrust',
    earlyHELOCDisclosure = 'EarlyHELOCDisclosure',
    pricingAndTerms = 'PricingAndTerms',
    propertyValuation = 'PropertyValuation',
    appraisalWaiver = 'AppraisalWaiver',
}

const postLegalDocuments = async (docTypes: string[]) => {
    return await httpClient.post('/legal', {
        docTypes: docTypes,
    })
}

const startPlaidReportFetch = async (plaidPublicToken: string, plaidDefaultAccountId: string, institutionInfo: string, isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/startPlaidReportFetch', {
            public_token: plaidPublicToken,
            default_account_id: plaidDefaultAccountId,
            institutionInfo,
        })
    }
    return await httpClient.post('/startPlaidReportFetch', {
        public_token: plaidPublicToken,
        default_account_id: plaidDefaultAccountId,
        institutionInfo,
    })
}

const updateApplicantNameFromNonEntityOwnerName = async (selectedOwnerName: string) => {
    return await httpClient.post('/updateApplicantNameFromNonEntityOwnerName', { selectedOwnerName })
}

export interface IUpdateApplicantInfoPayload {
    firstName?: string
    middleName?: string
    lastName?: string
    phoneNumber?: string
    addressFromMultiFieldForm?: boolean
    dateOfBirth?: string
    ssn?: string
    email?: string
    parentApplicantRelationship?: ParentApplicantRelationshipType
}
const postUpdateApplicantFields = (postBody: IUpdateApplicantInfoPayload, isCoApplicant?: boolean) => {
    return postRoute('/updateApplicantFields', postBody, isCoApplicant)
}

export interface IUpdateApplicantAddressPayload {
    addressApt?: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
    residenceType: ResidenceType
    isPropertyAddress: boolean
}
const postUpdateAddress = (postBody: IUpdateApplicantAddressPayload, isCoApplicant?: boolean) => {
    return postRoute('/updateApplicantAddress', postBody, isCoApplicant)
}

const getDidUnderwritingRelatedInfoChange = (postBody: Object) => {
    return postRoute('/getDidUnderwritingRelatedInfoChange', postBody)
}

// Keep in sync with enum in aven_backend/src/entity/applicant.ts
enum MaritalStatus {
    MARRIED = 'MARRIED',
    UNMARRIED = 'UNMARRIED',
    SEPARATED = 'SEPARATED',
}

// Keep in sync with enum in aven_backend/src/entity/home.ts
enum ResidenceType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

// Mirrored in aven_backend and aven_python
enum StatedUsageType {
    HOME_IMPROVEMENT = 'Home Improvement',
    BALANCE_TRANSFER = 'Balance Transfer',
    OTHER = 'Other',
    UNSPECIFIED = 'UNSPECIFIED',
}

const postUpdateMaritalStatus = (maritalStatus: MaritalStatus, isCoApplicant?: boolean) => {
    let sanitizedMaritalStatus = typeof maritalStatus === 'string' ? maritalStatus : ''
    sanitizedMaritalStatus = sanitizedMaritalStatus.toUpperCase()

    return postRoute('/updateApplicantMaritalStatus', { maritalStatus: sanitizedMaritalStatus }, isCoApplicant)
}

const getLegalDocumentDownload = async (docType: string) => {
    return await httpClient.get('/legal/document', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

const uploadDocument = async (fileUrl: string, documentTypePath: string, documentIndex: string, isCoApplicant?: boolean) => {
    const formData = new FormData()
    formData.append('file', fileUrl)
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 100000,
        })
    }
    return await httpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 100000,
    })
}

const documentUpdateStatus = async (documentUploadType: string) => {
    return await httpClient.post('/uploadDocument/updateStatus', {
        documentUploadType,
    })
}

const getIsWaitingOnManualDocumentVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/getIsWaitingOnManualDocumentVerification', {})
    }
    return await httpClient.get('/getIsWaitingOnManualDocumentVerification', {})
}

const getOfferContingencyStatus = async () => {
    return await httpClient.get('/getOfferContingencyStatus', {})
}

const beginPayStubVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginPayStubVerification', {})
    }
    return await httpClient.get('/bank/beginPayStubVerification', {})
}

const beginTaxReturnVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginTaxReturnVerification', {})
    }
    return await httpClient.get('/bank/beginTaxReturnVerification', {})
}

const getPrefilledInformationForMailerLead = async (inviteCode: string) => {
    return await httpClient.post('/getPrefilledInformationForMailerLead', { inviteCode })
}

const sendUserFeedbackEmail = async (feedbackText: string, applicantId: number) => {
    return httpClient.post('/userFeedback/email', {
        feedbackText,
        applicantId,
    })
}

const createTrustPilotUniqueLink = async (returnToken2: string) => {
    return httpClient.post('/createTrustPilotUniqueLink', {
        returnToken2,
    })
}

const getTrustInfo = async () => {
    return await httpClient.get('/getTrustInfo')
}

const postTrustInfoForm = async (postBody: object) => {
    return await httpClient.post('/postTrustInfoForm', postBody)
}

export type PartialApplicantInfo = { firstName: string; lastName: string }
const getStatePageModules = async (screenName: string, state?: string, partialApplicantInfo?: PartialApplicantInfo, partialCoApplicantInfo?: PartialApplicantInfo) => {
    const postBody = Object.fromEntries(
        Object.entries({
            screenName: screenName,
            state: state,
            partialApplicantInfo: partialApplicantInfo,
            partialCoApplicantInfo: partialCoApplicantInfo,
        }).filter(([key, value]) => !!value) // Filter any non truthy value so it doesn't get sent to BE
    )

    return httpClient.post('/stateModules', postBody)
}

const isStateActive = async (twoLetterStateCode: string, streetLine?: string) => {
    // Street line is optionally passed to the BE to determine if this is a whitelisted address
    // If no address line is passed there will not be any whitelisting consideration
    return httpClient.post('/stateModules/active', { stateCode: twoLetterStateCode, streetLine })
}

const getSavingsForTicker = async () => {
    return await httpClient.get('/getSavingsForTicker')
}

// Keep in sync with aven_backend/src/controller/stateModulesController.ts
export enum StateModules {
    RequiredDocumentAck = 'RequiredDocumentAck',
}
interface ModuleValue {
    type: StateModules
    id: number
    value: any
}
export interface SaveStateModuleValuesPayload {
    moduleValues: ModuleValue[]
}
const saveStateModuleValues = async (stateModuleValues: SaveStateModuleValuesPayload) => {
    return await httpClient.post('/stateModules/values', stateModuleValues)
}

const getIsIncomeVerified = async (purpose: IncomeVerificationPurpose) => {
    return await httpClient.get('/getIsIncomeVerified', { params: { purpose } })
}

// keep in line with aven_backend
export enum IncomeVerificationPurpose {
    automatic = 'automatic',
    plaid = 'plaid',
    manual = 'manual',
}

export {
    abandonApplication,
    getTrustInfo,
    postTrustInfoForm,
    getPrefilledInformationForMailerLead,
    getIsWaitingOnManualDocumentVerification,
    getOfferContingencyStatus,
    beginPayStubVerification,
    beginTaxReturnVerification,
    getApplicantReturning,
    getNextHomeApplicationAction,
    getLegalDocument,
    getLegalDocumentForSession,
    getHtmlLegalDocument,
    LegalDocumentTypes,
    postLegalDocuments,
    plaidReportFetchState,
    startPlaidReportFetch,
    postUpdateApplicantFields,
    getDidUnderwritingRelatedInfoChange,
    postUpdateMaritalStatus,
    getLegalDocumentDownload,
    uploadDocument,
    documentUpdateStatus,
    sendUserFeedbackEmail,
    updateApplicantNameFromNonEntityOwnerName,
    MaritalStatus,
    StatedUsageType,
    createTrustPilotUniqueLink,
    ResidenceType,
    getStatePageModules,
    saveStateModuleValues,
    getSavingsForTicker,
    postUpdateAddress,
    getApplicantReturningHMDA,
    isStateActive,
    getIsIncomeVerified,
    getApplicantReturningToDocumentUploadPortal,
}
