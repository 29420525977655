import { defaultPagePaths } from '@/experiments/src/routes/marketingRoutes'
import { ExperimentName } from '@/experiments/experimentName'
import { HeraclesParameter } from '@/experiments/heraclesParameter'

export class PostNov2021Policy extends HeraclesParameter {
    EXPERIMENT_NAME = ExperimentName.postNov2021
    // @ts-ignore
    EXPERIMENT_BASE_PATH = defaultPagePaths.DEFAULT
    // @ts-ignore
    JOIN_PATH = defaultPagePaths.DEFAULT_JOIN

    BALANCE_TRANSFER_FEE_PERCENT = 0.02
}
