import 'url-search-params-polyfill'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { inspect, logger } from '@/utils/logger'
import { httpClient } from '@/utils/http-client'
import LogRocket from 'logrocket'

export const featureFlags = [sessionStorageKey.trustsFeatureFlag]

export const setLocalMetadata = () => {
    if (document.referrer) {
        const parser = new URL(document.referrer)
        window.logEvent('system_aven_init', { referrer: document.referrer, referring_domain: parser.hostname })
    }

    // Parsing invite code from query params for tryaven.com redirects
    // https://tryaven.com/BLAH1234 -> https://aven.com/?referrer=tryaven%2FBLAH1234 where %2F is url encoding of a forward slash '/'
    const query = new URLSearchParams(window.location.search)
    logger.info(`query ${query}; referrer ${document.referrer}; location href ${window.location.href}`)

    if (query.has('referrer') || query.has('invitecode') || query.has('code')) {
        const inviteCode = query.get('referrer')?.split('/')?.[1] || query.get('invitecode') || query.get('code')
        if (inviteCode) {
            appSessionStorage.setItem(sessionStorageKey.inviteCode, inviteCode)
        }
    } else if (query.has('pifinvitecode')) {
        const pifInviteCode = query.get('pifinvitecode') as string
        appSessionStorage.setItem(sessionStorageKey.pifInviteCode, pifInviteCode)
    }

    featureFlags.forEach((flag) => {
        if (query.get(flag)) {
            appSessionStorage.setItem(flag, 'True')
        }
    })
}

export const submitLogRocketSessionUrl = async (logRocketSessionUrl: string, sessionId: string) => {
    logger.info(`Submitting LogRocket sessionUrl: ${logRocketSessionUrl}`)
    appSessionStorage.setItem(sessionStorageKey.logRocketInitialized, 'true')
    appSessionStorage.setItem(sessionStorageKey.logRocketUrl, logRocketSessionUrl)
    try {
        await httpClient.post('/ana/logRocketUrl', { sessionId, logRocketSessionUrl })
    } catch (error) {
        logger.warn(`submission failed, LogRocket sessionUrl, ${logRocketSessionUrl}`, null /* event */, error)
    }
}

export const logRocketIdentify = () => {
    try {
        const loanApplicationId = appSessionStorage.getItem(sessionStorageKey.loanApplicationId)
        const applicantId = appSessionStorage.getItem(sessionStorageKey.applicantId)
        const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
        const experimentName = appSessionStorage.getItem(sessionStorageKey.experimentName)
        const traits: any = {}

        if (loanApplicationId) {
            traits.loanApplicationId = loanApplicationId
        }

        if (applicantId) {
            traits.applicantId = applicantId
        }

        if (experimentName) {
            traits.experimentName = experimentName
        }

        LogRocket.identify(sessionId as string, traits)
    } catch (error) {
        logger.info(`LogRocket failed to identify: ${error}`)
    }
}

export const initLogRocket = () => {
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
    const rootHostname = process.env.VUE_APP_AVEN_URL.replace(/^https?:\/\//gi, '')
    if (sessionId) {
        if (process.env.VUE_APP_LOGROCKET_ID) {
            logger.info(`initLogRocket with sessionId: ${sessionId}`)
            try {
                LogRocket.init(process.env.VUE_APP_LOGROCKET_ID, { rootHostname })
                logger.info(`LogRocket initialized attempting to identify session`)
                logRocketIdentify()
                logger.info(`Attempting to get logrocket sessionUrl for sessionID ${sessionId}`)
                LogRocket.getSessionURL((sessionURL) => submitLogRocketSessionUrl(sessionURL, sessionId))
                // This will attempt to start a new session if the LR URL does not exist in storage
                // helps to solve the rare case where there is an issue with the LR connection
                const MAX_LR_RETRYS = 5
                let retryNumber = 0
                const logRocketTimer = setInterval(() => {
                    if (!appSessionStorage.getItem(sessionStorageKey.logRocketUrl) && retryNumber < MAX_LR_RETRYS) {
                        logger.info('Could not find logRocketUrl, reseting session')
                        LogRocket.startNewSession()
                        logRocketIdentify()
                        LogRocket.getSessionURL((sessionURL) => submitLogRocketSessionUrl(sessionURL, sessionId))
                    } else if (retryNumber >= MAX_LR_RETRYS) {
                        logger.info('logRocket hit max retries stoping reset loop')
                        clearInterval(logRocketTimer)
                    } else {
                        logger.info('Found logRocketUrl stoping reset interval')
                        clearInterval(logRocketTimer)
                    }
                    retryNumber++
                }, 30000)
            } catch (error) {
                logger.info(`LogRocket failed to initialize for sessiondId: ${sessionId}`)
            }
        } else {
            logger.info(`Skipping logRocket initialization on ${process.env.VUE_APP_NODE_ENV} env`)
            submitLogRocketSessionUrl('local dev test logrocket session url', sessionId)
        }
    } else {
        logger.error('Could not initialize LogRocket with no sessionId!')
    }
}

export const initNextDoor = () => {
    // provided by nextdoor team
    // eslint-disable-next-line no-extra-semi
    ;(function (win, doc, sdk_url) {
        if (win.ndp) return
        const tr = (win.ndp = function () {
            // eslint-disable-next-line prefer-rest-params,prefer-spread
            tr.handleRequest ? tr.handleRequest.apply(tr, arguments) : tr.queue.push(arguments)
        } as any)
        tr.queue = []
        const s = 'script'
        const new_script_section = doc.createElement(s)
        new_script_section.async = !1
        new_script_section.src = sdk_url
        const insert_pos = doc.getElementsByTagName(s)[0]
        if (insert_pos.parentNode) {
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos)
        }
    })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js')
    window.ndp('init', process.env.VUE_APP_NEXTDOOR_PIXEL, {})
    window.ndp('track', 'PAGE_VIEW')
}

/**
 * The externalId argument for the the FB pixel (i.e. this frontend code)
 * and the FB Conversions API (i.e. the backend code in facebookPixelProvider.ts) need to match.
 * The externalId is retrieved from the backend.
 */
export const maybeInitFacebook = (externalId?: string | null) => {
    if (!externalId) {
        logger.log(`maybeInitFacebook() decide to not init because no externalId provided`)
        return
    }

    try {
        // @ts-ignore
        // eslint-disable-next-line no-extra-semi
        ;(function (f, b, e, v, n: any, t: any, s: any) {
            if (f.fbq) return
            n = f.fbq = function () {
                // eslint-disable-next-line prefer-rest-params,prefer-spread
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = '2.0'
            n.queue = []
            t = b.createElement(e)
            t.async = !1
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

        logger.log(`maybeInitFacebook() decided to init with externalId ${externalId}`)
        window.fbq('init', process.env.VUE_APP_FACEBOOK_PIXEL, { external_id: externalId })
        window.fbq('track', 'PageView')
    } catch (e) {
        logger.error('Failed to initialize fabebook', inspect(e))
    }
}

export const REDDIT_EVENTS = {
    PageVisit: 'PageVisit',
    Lead: 'Lead',
    ViewContent: 'ViewContent',
}

export const initReddit = () => {
    // @ts-ignore
    // eslint-disable-next-line no-extra-semi
    ;(function (w, d) {
        if (!w.rdt) {
            const p = (w.rdt = function () {
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params,prefer-spread
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)
            })
            // @ts-ignore
            p.callQueue = []
            const t = d.createElement('script')
            ;(t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !1)
            const s = d.getElementsByTagName('script')[0]
            // @ts-ignore
            s.parentNode.insertBefore(t, s)
        }
    })(window, document)
    window.rdt('init', process.env.VUE_APP_REDDIT_PIXEL)
    window.rdt('track', 'PageVisit')
}

export const initTrustPilot = () => {
    // @ts-ignore
    // eslint-disable-next-line no-extra-semi
    ;(function (w, d) {
        if (!w.Trustpilot) {
            // @ts-ignore
            const t = d.createElement('script')
            ;(t.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'), (t.async = !1)
            const s = d.getElementsByTagName('script')[0]
            // @ts-ignore
            s.parentNode.insertBefore(t, s)
        }
    })(window, document)
}
