import { marketingPagePaths } from '@/routes/marketingRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { realEstateAgentPagePaths } from '@/routes/realEstateAgentRoutes'

export const realEstateAgentEntryPagePaths: string[] = [marketingPagePaths.REALESTATE_AGENTS]

export const realEstateAgentReturnPagePathPairs: string[][] = []

export const realEstateAgentFlow: string[] = [realEstateAgentPagePaths.AGENT_INFO, realEstateAgentPagePaths.PIF_OFFER]

export const realEstateAgentBackGuardPagePaths: string[] = []

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map()

// Could be a unit test
if (!checkAreAllPathsUnique(realEstateAgentFlow, optionalFlowMap)) {
    throw new Error('mailer origination page paths not unique!')
}

export const getRealEstateAgentNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (realEstateAgentEntryPagePaths.some((path) => checkPathsMatch(path, currentPath))) {
        return realEstateAgentFlow[0]
    }

    for (const [path, nextPath] of realEstateAgentReturnPagePathPairs) {
        if (checkPathsMatch(path, currentPath)) {
            return nextPath
        }
    }

    let nextPath: string | null
    if (routeOption) {
        nextPath = tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    } else {
        nextPath = tryGetNextFlowPath(currentPath, realEstateAgentFlow, optionalFlowMap)
    }
    return nextPath
}
