import { HeraclesParameter } from '@/experiments/heraclesParameter'
import { ExperimentName } from '@/experiments/experimentName'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'

export class MoapV1NoBtFeePolicy extends HeraclesParameter {
    EXPERIMENT_NAME = ExperimentName.moapV1NoBtFee
    EXPERIMENT_BASE_PATH = experimentPagePaths.SALUTATIONS_AVEN
    JOIN_PATH = experimentPagePaths.SALUTATIONS_AVEN_JOIN
    BALANCE_TRANSFER_FEE_PERCENT = 0.0
}
