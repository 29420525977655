import { ExperimentName } from '@/experiments/experimentName'

// Some typescript magic: this enforces that ALL keys of ExperimentName have an associated key
// This makes sure we don't have any Experiments that are missing strings 😄
export const ENGLISH_TRANSLATIONS: { experiments: { [T in ExperimentName]: {} } } = {
    experiments: {
        [ExperimentName.preJuly2021Default]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees.',
            },
        },
        [ExperimentName.postJuly2021Default]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.simpleAPRTable]: {
            aprAltText: '3.99% - 9.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 03, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred. Late fee of $29 and returned payment fee (NSF) of $15. FICO score used is Experian FICO V9.  Min FICO 620.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.99-9.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `<p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 03, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred. Late fee of $29 and returned payment fee (NSF) of $15. FICO score used is Experian FICO V9.  Min FICO 620.</p>`,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.default20210802]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 3, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 19% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 7.13.21, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.testimonials]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 3, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 19% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 7.13.21, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.internalHomeNoLoyalty]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. Cash Out fee is 2% of each transfer.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
        },
        [ExperimentName.internalHomeLoyalty]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. Cash Out fee is 2% of each transfer.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
        },
        [ExperimentName.beforeBatch14]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
        },
        [ExperimentName.batch14Control]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
        },
        [ExperimentName.batch14OriginationFee]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: 'No annual fee',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account. Origination fee of $99 applies.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account. Origination fee of $99 applies.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any annual or balance transfer fees.',
            },
        },
        [ExperimentName.batch14MonthlyFee]: {
            aprAltText: '3.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br>On a credit card.',
            heroSubtitle: 'Get rates like a home equity line of credit but with the simplicity of a credit card. Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account. $9.99 monthly membership fee.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>. 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account. $9.99 monthly membership fee.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
`,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination or balance transfer fees.',
            },
        },
        [ExperimentName.batch14BtCashBack]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'With 1% cash back on balance transfers.<sup><a href="#footnote_5">5</a></sup> Home equity card.',
            heroSubtitle: 'Home equity on a card. 1% cash back on upfront balance transfers.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Earning 1% cashback on balance transfers applies to Balance Transfers only. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
        },
        [ExperimentName.batch15CashBackRedemption]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        // Literal copy and paste of batch 15 cash back redemption
        [ExperimentName.batch16CashBackRedemption]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        // Literal copy and paste of batch 15 cash back redemption
        [ExperimentName.batch17CashBackRedemption]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `<p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>`,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        // Literal copy and paste of batch 15 cash back redemption
        [ExperimentName.batch18CashBackRedemption]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "How Much are Home Equity Loan Closing Costs?" <a href="https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/" target="_blank">https://www.lendingtree.com/home/home-equity/home-equity-loan-closing-costs/</a>.
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.batch16NewHomeowner]: {
            aprAltText: '4.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back. Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_4">4</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 4.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. Source: <a href="http://zillow.mediaroom.com/2018-08-21-Homeowners-Can-Pay-Nearly-9-500-per-Year-in-Hidden-Costs" target="_blank">Zillow</a>
                <p id="footnote_4">4. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p> 
                Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal “Money Rates” table (the “Index”). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount drawn to bank account.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.moapV1]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br />Credit card rewards.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 19% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 7.13.21, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.moapV1NoBtFee]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br />Credit card rewards.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount transferred. There is no Balance Transfer fee.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only and can only go as low as 4.99%. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee is 2% of amount transferred. There is no Balance Transfer fee.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        // Copy pasta of moap v1 (but with 3.49% replaced with 2.99%)
        [ExperimentName.moapV2]: {
            aprAltText: '2.99% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br />Credit card rewards.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of March 16, 2020 is 0.25% Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 2.99-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .aprAltText
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Offer valid on non-promotional credit card rates only and can only go as low as 4.99%. Image of credit card statement must clearly and visibly identify applicant name and include credit card interest charged along with APR.</p> 
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of March 16, 2020 is 0.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.bankrate]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br />Credit card rewards.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p> 
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.debtV1]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity rates.<br />Credit card rewards.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?" <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/</a>) wallethub.com on 4.6.20, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_6">6. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p> 
            `,
            prequalFooter: `<p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>`,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>6</sup>',
            },
        },
        [ExperimentName.postNov2021]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 250k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 18% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 03.21.22 showing the average rate for credit cards in the US for people with good credit is 18.98% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “Credit Card Fee Study: What's Normal and What's Not?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
                <p id="footnote_6">6. Amount of 'Interest Saved' is calculated as: (Current Cumulative Balance) * (Revolving Balance Percentage) * (Weighted Average Interest Rate).<br/>
                Amount conservatively assumes Aven’s weighted average interest rate is half of customers’ existing interest rates, as guaranteed, multiplied by the cumulative balance charged to all Aven Cards.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.postNov2021NoThirdLiens]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 250k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 18% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 03.21.22 showing the average rate for credit cards in the US for people with good credit is 18.98% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “Credit Card Fee Study: What's Normal and What's Not?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
                <p id="footnote_6">6. Amount of 'Interest Saved' is calculated as: (Current Cumulative Balance) * (Revolving Balance Percentage) * (Weighted Average Interest Rate).<br/>
                Amount conservatively assumes Aven’s weighted average interest rate is half of customers’ existing interest rates, as guaranteed, multiplied by the cumulative balance charged to all Aven Cards.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.postNov2021NoThirdLiensDirectMail]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 250k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 3, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 18% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 03.21.22 showing the average rate for credit cards in the US for people with good credit is 18.98% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “Credit Card Fee Study: What's Normal and What's Not?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
                <p id="footnote_6">6. Amount of 'Interest Saved' is calculated as: (Current Cumulative Balance) * (Revolving Balance Percentage) * (Weighted Average Interest Rate).<br/>
                Amount conservatively assumes Aven’s weighted average interest rate is half of customers’ existing interest rates, as guaranteed, multiplied by the cumulative balance charged to all Aven Cards.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.postMay2022]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 250k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 18% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 03.21.22 showing the average rate for credit cards in the US for people with good credit is 18.98% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “Credit Card Fee Study: What's Normal and What's Not?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
                <p id="footnote_6">6. Amount of 'Interest Saved' is calculated as: (Current Cumulative Balance) * (Revolving Balance Percentage) * (Weighted Average Interest Rate).<br/>
                Amount conservatively assumes Aven’s weighted average interest rate is half of customers’ existing interest rates, as guaranteed, multiplied by the cumulative balance charged to all Aven Cards.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.postMay2022FFTRUL]: {
            aprAltText: '3.99% - 11.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 250k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.99-11.99%. The average for other cards in the US for people with good credit is over 18% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 03.21.22 showing the average rate for credit cards in the US for people with good credit is 18.98% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “Credit Card Fee Study: What's Normal and What's Not?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
                <p id="footnote_6">6. Amount of 'Interest Saved' is calculated as: (Current Cumulative Balance) * (Revolving Balance Percentage) * (Weighted Average Interest Rate).<br/>
                Amount conservatively assumes Aven’s weighted average interest rate is half of customers’ existing interest rates, as guaranteed, multiplied by the cumulative balance charged to all Aven Cards.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Federal Funds Target Rate - Upper Limit (the “FFTR-UL”) index. The FFTR-UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its “Money Rates” section. The FFTR-UL as of May 5, 2022 is 1%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is the greater of $5 or 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
        [ExperimentName.start]: {
            aprAltText: '3.49% - 10.99%',
            feeTitle: '$0 to get.<br />No origination fee.',
            heroTitle: 'Home equity card.<br>With 1.5% unlimited cash back.<sup><a href="#footnote_5">5</a></sup> Lines up<br />to 100k.',
            heroSubtitle: 'Home equity on a card. Earn 1.5% unlimited cashback on all purchases.<sup><a href="#footnote_5">5</a></sup> Decision in minutes. 100% online.',
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 3, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to <a href="/docs/rewards15Terms.pdf" target="_blank">Rewards Terms & Conditions.</a></p>
                <p id="footnote_2">2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 19% APR. (Source: <a href="https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841/" target="_blank">"What is the average credit card rate?"</a>) wallethub.com on 7.13.21, showing the average rate for credit cards in the US for people with good credit is 19.49% APR.</p>
                <p id="footnote_3">3. These comparison charts use the midpoint of each card's APR range. Sources: <a href="https://www.capitalone.com/credit-cards/platinum/" target="_blank" rel="noopener noreferrer">Capital One Platimum</a>, <a href="https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited?iCELL=6C1Y" target="_blank" rel="noopener noreferrer">Chase Freedom</a>, <a href="https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/?campaign=4051255~AX~en_US" target="_blank" rel="noopener noreferrer">Bank of America Cash Rewards Card</a></p>
                <p id="footnote_4">4. Source: "Home Equity Loan Rates" <a href="https://www.bankrate.com/home-equity/home-equity-loan-rates/" target="_blank">https://www.bankrate.com/home-equity/home-equity-loan-rates/</a>. 
                Source: "How Long Do I Need to Wait for a Home Equity Line?" <a href="https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html" target="_blank">https://homeguides.sfgate.com/long-need-wait-home-equity-line-88547.html</a>. 
                Source: “Average Credit Card Limit” <a href="https://turbo.intuit.com/blog/credit-cards/average-limit-4991/" target="_blank">https://turbo.intuit.com/blog/credit-cards/average-limit-4991/</a> .
                Source: “What’s a Good Personal Loan Interest Rate?” <a href="https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/" target="_blank">https://www.experian.com/blogs/ask-experian/whats-a-good-interest-rate-for-a-personal-loan/</a>. 
                Source: “Personal Loan Statistics” <a href="https://www.lendingtree.com/personal/personal-loans-statistics/" target="_blank">https://www.lendingtree.com/personal/personal-loans-statistics/</a>. 
                Source: “2019 Credit Fee Study: What is Normal?” <a href="https://creditcards.usnews.com/articles/fee-survey" target="_blank">https://creditcards.usnews.com/articles/fee-survey</a>. 
                Source: “What to know about personal loan origination fees” <a href="https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees" target="_blank">https://www.creditkarma.com/personal-loans/i/personal-loan-origination-fees</a>.
                </p>
                <p id="footnote_5">5. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.</p>
            `,
            prequalFooter: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of January 1, 2022 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. You may avoid interest charges by paying the balance in full within 21 days after the close of the billing cycle (Grace Period). Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.</p>
            `,
            homeEquityLoan: {
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            },
        },
    },
}
