import { defaultPagePaths } from '@/experiments/src/routes/marketingRoutes'
import { MoapV1Policy } from '@/experiments/moapV1/moapV1Policy'
import { ExperimentName } from '@/experiments/experimentName'

export class Default20210802Policy extends MoapV1Policy {
    EXPERIMENT_NAME = ExperimentName.default20210802
    // @ts-ignore
    EXPERIMENT_BASE_PATH = defaultPagePaths.DEFAULT
    // @ts-ignore
    JOIN_PATH = defaultPagePaths.DEFAULT_JOIN
}
