import { experimentPageBasePaths, experimentPageJoinPaths } from '@/experiments/src/routes/marketingRoutes'
import { MoapV1Policy } from '@/experiments/moapV1/moapV1Policy'
import { ExperimentName } from '@/experiments/experimentName'

// all this policy does is show 100k landing page, everything is same (they can request 250k even)
export class StartPolicy extends MoapV1Policy {
    EXPERIMENT_NAME = ExperimentName.start
    EXPERIMENT_BASE_PATH = experimentPageBasePaths.START
    JOIN_PATH = experimentPageJoinPaths.START_JOIN
}
