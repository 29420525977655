import { RouteConfig } from 'vue-router'
import { marketingPageNames, marketingPagePaths } from '@/routes/marketingRoutes'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { ExperimentName } from '@/experiments/experimentName'
import { AvenRouteConfig } from '@/routes/router'
import { logger } from '@/utils/logger'

export const defaultPagePaths = {
    DEFAULT: '/',
    DEFAULT_JOIN: '',
}

// TODO: base and join should really be next to each other IMO, should reduce mistakes
export const experimentPageNames = {
    // base names
    JOIN_AVEN: 'joinaven',
    AVEN_CREDIT: 'avencredit',
    GO_AVEN: 'goaven',
    TRY_AVEN: 'tryaven',
    MEET_AVEN: 'meetaven',
    VISIT_AVEN: 'visitaven',
    FIND_AVEN: 'findaven',
    DISCOVER_AVEN: 'discoveraven',
    SALUTATIONS_AVEN: 'salutationsaven',
    AVEN_FINANCE: 'avenfinance',
    WITH_AVEN: 'withaven',
    BANKRATE: 'bankrate',
    DEBT: 'debt',
    START: 'start',
    ABTESTV1: 'abtestv1',

    // join names
    JOIN_AVEN_JOIN: 'joinavenjoin',
    AVEN_CREDIT_JOIN: 'avencreditjoin',
    GO_AVEN_JOIN: 'goavenjoin',
    TRY_AVEN_JOIN: 'tryavenjoin',
    MEET_AVEN_JOIN: 'meetavenjoin',
    VISIT_AVEN_JOIN: 'visitavenjoin',
    FIND_AVEN_JOIN: 'findavenjoin',
    DISCOVER_AVEN_JOIN: 'discoveravenjoin',
    SALUTATIONS_AVEN_JOIN: 'salutationsavenjoin',
    AVEN_FINANCE_JOIN: 'avenfinancejoin',
    WITH_AVEN_JOIN: 'withavenjoin',
    BANKRATE_JOIN: 'bankratejoin',
    DEBT_JOIN: 'debtjoin',
    START_JOIN: 'startjoin',
    ABTESTV1_JOIN: 'abtestv1join',
}

export const experimentPageBasePaths = {
    JOIN_AVEN: `/${experimentPageNames.JOIN_AVEN}`,
    AVEN_CREDIT: `/${experimentPageNames.AVEN_CREDIT}`,
    GO_AVEN: `/${experimentPageNames.GO_AVEN}`,
    MEET_AVEN: `/${experimentPageNames.MEET_AVEN}`,
    TRY_AVEN: `/${experimentPageNames.TRY_AVEN}`,
    VISIT_AVEN: `/${experimentPageNames.VISIT_AVEN}`,
    FIND_AVEN: `/${experimentPageNames.FIND_AVEN}`,
    DISCOVER_AVEN: `/${experimentPageNames.DISCOVER_AVEN}`,
    SALUTATIONS_AVEN: `/${experimentPageNames.SALUTATIONS_AVEN}`,
    AVEN_FINANCE: `/${experimentPageNames.AVEN_FINANCE}`,
    WITH_AVEN: `/${experimentPageNames.WITH_AVEN}`,
    BANKRATE: `/${experimentPageNames.BANKRATE}`,
    DEBT: `/${experimentPageNames.DEBT}`,
    START: `/${experimentPageNames.START}`,
    ABTESTV1: `/${experimentPageNames.ABTESTV1}`,
} as const
export type ExperimentBasePathKeys = keyof typeof experimentPageBasePaths
export type ExperimentBasePaths = typeof experimentPageBasePaths[ExperimentBasePathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

export const experimentPageJoinPaths = {
    JOIN_AVEN_JOIN: `/${experimentPageNames.JOIN_AVEN}/join`,
    AVEN_CREDIT_JOIN: `/${experimentPageNames.AVEN_CREDIT}/join`,
    GO_AVEN_JOIN: `/${experimentPageNames.GO_AVEN}/join`,
    MEET_AVEN_JOIN: `/${experimentPageNames.MEET_AVEN}/join`,
    TRY_AVEN_JOIN: `/${experimentPageNames.TRY_AVEN}/join`,
    VISIT_AVEN_JOIN: `/${experimentPageNames.VISIT_AVEN}/join`,
    FIND_AVEN_JOIN: `/${experimentPageNames.FIND_AVEN}/join`,
    DISCOVER_AVEN_JOIN: `/${experimentPageNames.DISCOVER_AVEN}/join`,
    SALUTATIONS_AVEN_JOIN: `/${experimentPageNames.SALUTATIONS_AVEN}/join`,
    AVEN_FINANCE_JOIN: `/${experimentPageNames.AVEN_FINANCE}/join`,
    WITH_AVEN_JOIN: `/${experimentPageNames.WITH_AVEN}/join`,
    BANKRATE_JOIN: `/${experimentPageNames.BANKRATE}/join`,
    DEBT_JOIN: `/${experimentPageNames.DEBT_JOIN}/join`,
    START_JOIN: `/${experimentPageNames.START_JOIN}/join`,
    ABTESTV1_JOIN: `/${experimentPageNames.ABTESTV1_JOIN}/join`,
} as const
export type ExperimentJoinPathKeys = keyof typeof experimentPageJoinPaths
export type ExperimentJoinPaths = typeof experimentPageJoinPaths[ExperimentJoinPathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

export const experimentPagePaths = {
    // experiment page base paths
    ...experimentPageBasePaths,

    // experiment page join paths
    ...experimentPageJoinPaths,
}

const BeforeBatch14AndBatch14ControlLanding = () => import(/* webpackChunkName: "batch14-landing-experiment" */ '@/experiments/src/pages/marketing/BeforeBatch14AndBatch14ControlLanding.vue')
const Batch14Landing = () => import(/* webpackChunkName: "batch14-landing-experiment" */ '@/experiments/src/pages/marketing/Batch14Landing.vue')
const Batch15Landing = () => import(/* webpackChunkName: "batch15-landing-experiment" */ '@/experiments/src/pages/marketing/Batch15Landing.vue')
const Batch16Landing = () => import(/* webpackChunkName: "batch16-landing-experiment" */ '@/experiments/src/pages/marketing/Batch16Landing.vue')
const MoapV1Landing = () => import(/* webpackChunkName: "moap-v1-landing-experiment" */ '@/experiments/src/pages/marketing/MoapV1Landing.vue')
const MoapV1NoBtFeeLanding = () => import(/* webpackChunkName: "moap-v1-no-bt-fee-landing-experiment" */ '@/experiments/src/pages/marketing/MoapV1NoBtFeeLanding.vue')
const MoapV2Landing = () => import(/* webpackChunkName: "moap-v2-landing-experiment" */ '@/experiments/src/pages/marketing/MoapV2Landing.vue') // copy paste of moap v1...
const PreJuly2021DefaultLanding = () => import(/* webpackChunkName: "pre-july-2021-default-experiment" */ '@/experiments/src/pages/marketing/PreJuly2021DefaultLanding.vue')
const PostJuly2021DefaultLanding = () => import(/* webpackChunkName: "post-july-2021-default-experiment" */ '@/experiments/src/pages/marketing/PostJuly2021DefaultLanding.vue')
const TestimonialsLanding = () => import(/* webpackChunkName: "testimonials-experiment" */ '@/experiments/src/pages/marketing/TestimonialsLanding.vue')
const BankRateLanding = () => import(/* webpackChunkName: "bankrate-experiment" */ '@/experiments/src/pages/marketing/BankRateLanding.vue')
const DebtV1Landing = () => import(/* webpackChunkName: "debtv1-experiment" */ '@/experiments/src/pages/marketing/DebtV1Landing.vue')
const StartLanding = () => import(/* webpackChunkName: "start-experiment" */ '@/experiments/src/pages/marketing/Start100KLanding.vue')
const Default20220323Landing = () => import(/* webpackChunkName: "abtestv1-experiment" */ '@/experiments/src/pages/marketing/Default20220323Landing.vue')
const PostMay2022FFTRULLanding = () => import(/* webpackChunkName: "postmay2022FFTRUL-experiment" */ '@/experiments/src/pages/marketing/PostMay2022FFTRULLanding.vue')
const PostMay2022Landing = () => import(/* webpackChunkName: "postmay2022-experiment" */ '@/experiments/src/pages/marketing/PostMay2022Landing.vue')

const getDefaultLandingPageLazyLoad = () => {
    const experimentName = appSessionStorage.getItem(sessionStorageKey.experimentName)
    const experimentOverrides = JSON.parse(appSessionStorage.getItem(sessionStorageKey.experimentOverrides) || '{}') // prerender needs this '{}'
    const underwritingPolicyExperimentName = experimentOverrides.underWritingPolicyExperiment
    if (underwritingPolicyExperimentName) {
        return resolveLandingPageOverride(underwritingPolicyExperimentName)
    }
    switch (experimentName) {
        case ExperimentName.preJuly2021Default:
            return PreJuly2021DefaultLanding
        case ExperimentName.postJuly2021Default:
            return PostJuly2021DefaultLanding
        case ExperimentName.default20210802:
            return Default20220323Landing
        case ExperimentName.postNov2021:
            return Default20220323Landing
        case ExperimentName.postMay2022FFTRUL:
            return PostMay2022FFTRULLanding
        case ExperimentName.postMay2022:
            return PostMay2022Landing
        default:
            // we have to return a default landing page on first load because experimentName is not set in sessionStorage until async request completes
            // we try to be as explicit as possible here but this is a nice fall-back for experiments whose policies can be default (or need to be set to a old/new default)
            // (i.e. postCurrentDateExperiments are usually fallback to whatever the current default is) however you would need to implement an experiment landing for something like Bankrate or CreditKarma leads
            // side note: there is logic elsewhere that catches non-implemented experiments (see getPolicyForExperiment.ts)
            return PostMay2022Landing
    }
}

const resolveLandingPageOverride = (underwritingPolicyExperimentName: string) => {
    switch (underwritingPolicyExperimentName) {
        case ExperimentName.postMay2022FFTRUL:
            return PostMay2022FFTRULLanding
        default:
            throw new Error('Received experiment override but no implemented landing page')
    }
}

const landingPageRoutes = (): AvenRouteConfig[] => [
    {
        path: marketingPagePaths.LANDING,
        name: marketingPageNames.LANDING,
        component: getDefaultLandingPageLazyLoad(),
        props: {
            codeRequired: false,
            showForgotCodeOption: true,
            requiresLogViewEvent: true,
        },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PIF_SHARED_LINK_LANDING,
        name: marketingPageNames.PIF_SHARED_LINK_LANDING,
        props: {
            requiresLogViewEvent: false, // not necessary to implement for redirect paths
        },
        meta: { public: true },
        redirect: (to) => {
            appSessionStorage.setItem(sessionStorageKey.pifInviteCode, to.params.p)
            window.logEvent('event_pif_code_used', { pifInviteCode: to.params.p })
            return { path: marketingPageNames.PIF_LANDING, query: { pifinvitecode: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.PIF_SHARED_LOVE_LANDING,
        name: marketingPageNames.PIF_SHARED_LOVE_LANDING,
        props: {
            requiresLogViewEvent: false, // not necessary to implement for redirect paths
        },
        meta: { public: true },
        redirect: (to) => {
            appSessionStorage.setItem(sessionStorageKey.pifInviteCode, to.params.p)
            window.logEvent('event_pif_code_used', { pifInviteCode: to.params.p })
            return { path: marketingPageNames.PIF_LANDING, query: { pifinvitecode: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.PIF_LANDING,
        name: marketingPageNames.PIF_LANDING,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_JOIN,
        name: marketingPageNames.LANDING_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_INVITE,
        name: marketingPageNames.LANDING_INVITE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
]

// TODO: Why do we have a bunch of paths again? Makes life really complicated
const landingExperimentPageRoutes = (): AvenRouteConfig[] => [
    {
        path: experimentPagePaths.MEET_AVEN,
        name: experimentPageNames.MEET_AVEN,
        component: BeforeBatch14AndBatch14ControlLanding,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.MEET_AVEN_JOIN,
        name: experimentPageNames.MEET_AVEN_JOIN,
        component: BeforeBatch14AndBatch14ControlLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.TRY_AVEN,
        name: experimentPageNames.TRY_AVEN,
        component: TestimonialsLanding,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.TRY_AVEN_JOIN,
        name: experimentPageNames.TRY_AVEN_JOIN,
        component: TestimonialsLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.JOIN_AVEN,
        name: experimentPageNames.JOIN_AVEN,
        component: Batch14Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.JOIN_AVEN_JOIN,
        name: experimentPageNames.JOIN_AVEN_JOIN,
        component: Batch14Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_CREDIT,
        name: experimentPageNames.AVEN_CREDIT,
        component: Batch14Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_CREDIT_JOIN,
        name: experimentPageNames.AVEN_CREDIT_JOIN,
        component: Batch14Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GO_AVEN,
        name: experimentPageNames.GO_AVEN,
        component: Batch14Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GO_AVEN_JOIN,
        name: experimentPageNames.GO_AVEN_JOIN,
        component: Batch14Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.VISIT_AVEN,
        name: experimentPageNames.VISIT_AVEN,
        component: Batch15Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.VISIT_AVEN_JOIN,
        name: experimentPageNames.VISIT_AVEN_JOIN,
        component: Batch15Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FIND_AVEN,
        name: experimentPageNames.FIND_AVEN,
        component: Batch16Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FIND_AVEN_JOIN,
        name: experimentPageNames.FIND_AVEN_JOIN,
        component: Batch16Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DISCOVER_AVEN,
        name: experimentPageNames.DISCOVER_AVEN,
        component: MoapV1Landing,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DISCOVER_AVEN_JOIN,
        name: experimentPageNames.DISCOVER_AVEN_JOIN,
        component: MoapV1Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.SALUTATIONS_AVEN,
        name: experimentPageNames.SALUTATIONS_AVEN,
        component: MoapV1NoBtFeeLanding,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.SALUTATIONS_AVEN_JOIN,
        name: experimentPageNames.SALUTATIONS_AVEN_JOIN,
        component: MoapV1NoBtFeeLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_FINANCE,
        name: experimentPageNames.AVEN_FINANCE,
        component: MoapV2Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_FINANCE_JOIN,
        name: experimentPageNames.AVEN_FINANCE_JOIN,
        component: MoapV2Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.WITH_AVEN,
        name: experimentPageNames.WITH_AVEN,
        component: PostMay2022FFTRULLanding,
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.WITH_AVEN_JOIN,
        name: experimentPageNames.WITH_AVEN_JOIN,
        component: PostMay2022FFTRULLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.BANKRATE,
        name: experimentPageNames.BANKRATE,
        component: BankRateLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.BANKRATE_JOIN,
        name: experimentPageNames.BANKRATE_JOIN,
        component: BankRateLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DEBT,
        name: experimentPageNames.DEBT,
        component: DebtV1Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DEBT_JOIN,
        name: experimentPageNames.DEBT_JOIN,
        component: DebtV1Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.ABTESTV1,
        name: experimentPageNames.ABTESTV1,
        component: Default20220323Landing,
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.ABTESTV1_JOIN,
        name: experimentPageNames.ABTESTV1_JOIN,
        component: Default20220323Landing,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.START,
        name: experimentPageNames.START,
        component: StartLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.START_JOIN,
        name: experimentPageNames.START_JOIN,
        component: StartLanding,
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
]

export const experimentMarketingPageRoutes = (): RouteConfig[] => [...landingPageRoutes(), ...landingExperimentPageRoutes()]
