// Polyfill
import 'intersection-observer'
import './utils/polyfillRouter'

// Set logging prototype as soon as possible
import Vue from 'vue'
Vue.prototype.$logEvent = window.logEvent
Vue.prototype.$logRedditEvent = (eventName: string) => {
    window.rdt('track', eventName)
    window.logEvent('event_reddit_pixel_fired', { eventName })
}
import VueCookies from 'vue-cookies'
import VueMask from 'v-mask'
import VueObserveVisibility from 'vue-observe-visibility'
import { logger } from '@/utils/logger'
import experimentsDirective from '@/experimentsDirective'
import App from './App.vue'
import NetworkUnavailable from './NetworkUnavailable.vue'
import router from './routes/router'
import './utils/validation'
import './utils/exception-handler'
import { i18n } from './utils/i18n'
import { maybeInitFacebook, initLogRocket, initNextDoor, initReddit, initTrustPilot } from './services/marketing'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { fireSessionIdRequest, isSessionDataPresent } from '@/services/sessionService'
import { isStateStale } from '@/utils/stateUtils'
import { experimentMarketingPageRoutes } from '@/experiments/src/routes/marketingRoutes'

if (window.prerender) {
    // Prevent network errors from being thrown during prerender mode
    logger.isNetworkLoggingEnabled = false
}

declare global {
    interface Window {
        Calendly: any
        logEvent: any
        Plaid: any
        LogRocket: any
        previousPath: string
        ndp: any
        fbq: any
        _fbq: any
        zE: any
        rdt: any
        Trustpilot: any
        mobileCheck: any
        // This will be set when we're in prerender mode
        prerender: any
    }
}

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueObserveVisibility)

Vue.use(VueMask, {
    // This allows us to use the 'X' character as a regular char
    // https://github.com/probil/v-mask#default-placeholders
    placeholders: {
        X: null,
    },
})

export const currentContextForLogging: any = {}

// WARNING: DO NOT CREATE MULTIPLE MIXINS UNLESS ABSOLUTELY NECESSARY. It is highly discouraged.
// https://vuejs.org/v2/guide/mixins.html#Global-Mixin
Vue.mixin({
    props: {
        requiresLogViewEvent: { type: Boolean },
    },
    created() {
        try {
            // set up context logging for all components
            const name = this.name
            const vnode = this.$vnode
            const uid = this.uid as string
            if (name || vnode?.tag || uid) {
                currentContextForLogging[name || vnode?.tag || uid] = this
            }

            // This is the second step to check if component requires log view event.
            // It loops through every component and checks if the `requiresLogViewEvent` exists
            // (The first step is router.ts)
            const options = this.$options
            if (options.propsData?.requiresLogViewEvent) {
                logger.log('Check if logViewEvent has been implemented on the container component in /pages')
                // @ts-ignore bc the component context is unavailable to us and since we've already confirmed that the component "requiresLogViewEvent", we can safely ignore the linter and check if the function exists
                if (typeof this.logViewEvent !== 'function') {
                    logger.error('Must implement logViewEvent(): { this.$logEvent(eventName, eventProps) } on every page view. Please implement for this page component.')
                }
            }
        } catch (e) {
            logger.error(`there is an error with the global mixin ${this?.name || this?.$vnode?.tag || this?.uid}`, e)
        }
    },
})

Vue.directive('experiments', experimentsDirective)

let isInitialized = false
const initVueAndThirdpartyServices = () => {
    // Don't let components get initialized twice (this function could be fired twice for various reasons)
    if (isInitialized) {
        return
    }

    // Don't bother initializing third party services when we're in prerender mode
    if (!window.prerender) {
        // Init logRocket ASAP
        initLogRocket()
    }

    logger.info('Adding experiment marketing page routes')
    experimentMarketingPageRoutes().forEach((route) => router.addRoute(route))

    // Listen for + log when vue-loaded is fired (helps with debugging)
    document.addEventListener('vue-loaded', function () {
        logger.log('Vue loaded!')
    })

    logger.log('Enabling vue...')
    new Vue({
        router,
        i18n,
        render: (h) => h(App),
    }).$mount('#app')

    // Don't bother initializing third party services when we're in prerender mode
    if (!window.prerender) {
        initNextDoor()
        initReddit()
        initTrustPilot()
    }
    isInitialized = true

    // my god batman, are we logging an event once every second???
    // no robin, it's 10 seconds now. but we won't save it to the db like you think.
    setInterval(() => {
        window.logEvent('still_here')
    }, 10000)

    // This triggers the prerender script to capture the page
    // In normal modes, fires a message to the console
    const vueLoadedEvent = new Event('vue-loaded')
    document.dispatchEvent(vueLoadedEvent)
}

const initVueNetworkUnavailable = () => {
    if (isInitialized) {
        return
    }

    // Don't bother sending to the backend, unlikely to succeed + generates tons of console errors
    logger.setNetworkLogging(false)
    logger.log('Enabling vue (network unavailable)...')
    new Vue({
        i18n,
        render: (h) => h(NetworkUnavailable),
    }).$mount('#app')

    isInitialized = true
}

// Enable this to test 'prerender mode' in a normal browser
// window.prerender = { default: 'default20210802' }

// Detect if we're in prerender mode, if so short circuit some code paths
if (window.prerender) {
    appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, 'prerender')
    appSessionStorage.setItem(sessionStorageKey.sessionId, 'prerender')

    const currentPath = window.location.pathname.split('/')[1]

    // This logic allows us to set an experiment for the prerender based on the path
    if (currentPath && window.prerender[currentPath]) {
        appSessionStorage.setItem(sessionStorageKey.experimentName, window.prerender[currentPath])
    } else {
        appSessionStorage.setItem(sessionStorageKey.experimentName, window.prerender['default'])
    }

    console.log('Current prerender experiment: ' + appSessionStorage.getItem(sessionStorageKey.experimentName))
}

function abortCallIfSessionIdNotCompleteWithinXSeconds(xmlHttpRequest: XMLHttpRequest, timeout: number, callback: () => void) {
    setTimeout(function () {
        if (!isSessionDataPresent()) {
            console.log('Took too long to load, aborting')
            xmlHttpRequest.abort()
            if (callback) {
                callback()
            }
        }
    }, timeout)
}

function init() {
    if (isStateStale()) {
        console.log(`Init hit with stale state, clearing appSessionStorage...`)
        appSessionStorage.clear()
    } else if (isSessionDataPresent()) {
        // No need to run the rest of the logic, init vue and return
        console.log('sessionId, sessionAccessJWT and experimentName already set')
        initVueAndThirdpartyServices()
        return
    }

    console.log('Waiting for sessionIdReady / networkUnavailable event...')

    window.addEventListener(
        'sessionIdReady',
        function () {
            logger.log('Received sessionIdReady event')
            initVueAndThirdpartyServices()
        },
        false
    )

    window.addEventListener(
        'networkUnavailable',
        function () {
            logger.log('Received networkUnavailable event')
            initVueNetworkUnavailable()
        },
        false
    )

    const queryParams = new URLSearchParams(window.location.search)
    const sessionIdReq = fireSessionIdRequest()
    abortCallIfSessionIdNotCompleteWithinXSeconds(sessionIdReq, 5000, function () {
        const timesReloaded = parseInt(queryParams.get('reloaded') as string) || 0

        if (timesReloaded < 3) {
            console.log('Force reloading page because sessionId call failed, page already reloaded ' + timesReloaded + ' times')

            queryParams.set('reloaded', `${timesReloaded + 1}`)
            window.location.search = queryParams.toString()
        } else {
            console.log('Dispatching networkUnavailable event')
            const networkUnavailableEvent = new Event('networkUnavailable')
            window.dispatchEvent(networkUnavailableEvent)
        }
    })
}

init()
