import { ExperimentName } from '@/experiments/experimentName'
import { ExperimentBasePaths, ExperimentJoinPaths } from '@/experiments/src/routes/marketingRoutes'

export class GlobalParameters {
    /*** THESE PARAMETERS DO NOT VARY BY POLICY ***/

    // Default Redemption ratio is 1x (i.e., 1 point = 1 cent)
    // If redeeming points at, say, 1.5x use 0.015 for this parameter
    public BASIC_CASH_BACK_REDEMPTION_POINTS_TO_DOLLAR_RATIO = 0.015

    // Default global line size limit used to return an array of months (5 year, 10 year or 10 year, 15 year)
    public LINE_SIZE_LIMIT_FOR_TERMS = (lineSize: number): number[] => (lineSize <= 25000 ? [5 * 12, 10 * 12] : [10 * 12, 15 * 12])
}

// from: https://docs.google.com/spreadsheets/d/1LXEWQyMq8vHU3nOjHqG-XoJhkB3umgSCkF4o4piVJYY/edit#gid=0
export abstract class HeraclesParameter extends GlobalParameters {
    /*** THESE PARAMETERS MUST VARY BY POLICY ***/

    public abstract EXPERIMENT_NAME: ExperimentName
    public abstract EXPERIMENT_BASE_PATH: ExperimentBasePaths
    public abstract JOIN_PATH: ExperimentJoinPaths

    // Dollars charged to card upon finishing account open
    public ORIGINATION_FEE = 0.0

    // Balance transfer fee percentage
    public BALANCE_TRANSFER_FEE_PERCENT = 0.0
}
