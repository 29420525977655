import { RouteConfig } from 'vue-router'

const routeName = 'agent'

const pageNames = {
    AGENT_INFO: 'info',
    PIF_OFFER: 'pif_offer',
}

export const realEstateAgentPagePaths = {
    AGENT_INFO: `/${routeName}/${pageNames.AGENT_INFO}`,
    PIF_OFFER: `/${routeName}/${pageNames.PIF_OFFER}`,
}

export const realEstateAgentRoutes: RouteConfig[] = [
    {
        path: realEstateAgentPagePaths.AGENT_INFO,
        component: () => import(/* webpackChunkName: "realEstateAgent" */ '@/pages/realEstateAgent/AgentInfo.vue'),
    },
    {
        path: realEstateAgentPagePaths.PIF_OFFER,
        component: () => import(/* webpackChunkName: "realEstateAgent" */ '@/pages/realEstateAgent/AgentPifOffer.vue'),
    },
]
