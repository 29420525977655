import { RouteConfig } from 'vue-router'

const routeName = 'support'

const supportPageNames = {
    SUPPORT_HOME: 'support_home',
    SUPPORT_ARTICLE: 'support_article',
    SUPPORT_CALL_BOOKING: 'support_call_booking',
}

const supportPagePaths = {
    SUPPORT_HOME: `/${routeName}`,
    SUPPORT_ARTICLE: `/${routeName}/article/:id`,
    SUPPORT_CALL_BOOKING: `/${routeName}/call_booking/:bookingUrl?`,
}

export const supportPageRoutes: RouteConfig[] = [
    {
        path: supportPagePaths.SUPPORT_HOME,
        name: supportPageNames.SUPPORT_HOME,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportLanding.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_CALL_BOOKING,
        name: supportPageNames.SUPPORT_CALL_BOOKING,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportCallBooking.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_ARTICLE,
        name: supportPageNames.SUPPORT_ARTICLE,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportArticle.vue'),
        meta: { public: true },
    },
]

export { supportPagePaths, supportPageNames }
