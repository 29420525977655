import { RouteConfig } from 'vue-router'
import { goToPageWithCleanState } from '@/utils/routerUtils'

export const marketingPageNames = {
    LANDING: 'landing',
    LANDING_JOIN: 'join',
    PIF_SHARED_LINK_LANDING: 'r',
    PIF_SHARED_LOVE_LANDING: 'love',
    PIF_LANDING: 'linkLanding',
    LANDING_INVITE: 'invite',
    ABOUT: 'about',
    CAREERS: 'careers',
    CONTACT: 'contact',
    PRIVACY: 'privacy',
    DISCLOSURES: 'disclosures',
    SURVEY_THANK_YOU: 'thankyou',
    MOBILE_APPS: 'app',
    REVIEWS: 'reviews',
    ADD_REVIEW: 'addReview',
    PAYMENT_CALCULATOR: 'paymentCalculator',
    FAQ: 'faq',
    REALESTATE_AGENTS: 'agent',
    LICENSES: 'licenses',
}

export const marketingPagePaths = {
    LANDING: `/`,
    LANDING_JOIN: `/${marketingPageNames.LANDING_JOIN}`,
    PIF_SHARED_LINK_LANDING: `/${marketingPageNames.PIF_LANDING}/:p`,
    PIF_SHARED_LOVE_LANDING: `/${marketingPageNames.PIF_SHARED_LOVE_LANDING}/:p`,
    PIF_LANDING: `/${marketingPageNames.PIF_LANDING}`,
    LANDING_INVITE: `/${marketingPageNames.LANDING_INVITE}`,
    ABOUT: `/${marketingPageNames.ABOUT}`,
    CAREERS: `/${marketingPageNames.CAREERS}`,
    PRIVACY: `/${marketingPageNames.PRIVACY}`,
    DISCLOSURES: `/${marketingPageNames.DISCLOSURES}`,
    SURVEY_THANK_YOU: `/survey/${marketingPageNames.SURVEY_THANK_YOU}`,
    MOBILE_APPS: `/${marketingPageNames.MOBILE_APPS}`,
    LICENSES: `/${marketingPageNames.LICENSES}`,
    CONTACT: `/${marketingPageNames.CONTACT}`,
    REVIEWS: `/${marketingPageNames.REVIEWS}`,
    ADD_REVIEW: `/${marketingPageNames.ADD_REVIEW}`,
    ADD_REVIEW_PARAMETERIZED: `/${marketingPageNames.ADD_REVIEW}/:p`,
    PAYMENT_CALCULATOR: `/${marketingPageNames.PAYMENT_CALCULATOR}`,
    FAQ: `/${marketingPageNames.FAQ}`,
    REALESTATE_AGENTS: `/${marketingPageNames.REALESTATE_AGENTS}`,
}

const infoPageRoutes: RouteConfig[] = [
    {
        path: marketingPagePaths.ABOUT,
        name: marketingPageNames.ABOUT,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/marketing/About.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.REVIEWS,
        name: marketingPageNames.REVIEWS,
        component: () => import(/* webpackChunkName: "reviews" */ '@/pages/marketing/Reviews.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.CAREERS,
        name: marketingPageNames.CAREERS,
        component: () => import(/* webpackChunkName: "careers" */ '@/pages/marketing/Careers.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PRIVACY,
        name: marketingPageNames.PRIVACY,
        component: () => import(/* webpackChunkName: "privacy" */ '@/pages/marketing/Privacy.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.DISCLOSURES,
        name: marketingPageNames.DISCLOSURES,
        component: () => import(/* webpackChunkName: "disclosures" */ '@/pages/marketing/ImportantDisclosures.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.CONTACT,
        name: marketingPageNames.CONTACT,
        component: () => import(/* webpackChunkName: "contact" */ '@/pages/marketing/Contact.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PAYMENT_CALCULATOR,
        name: marketingPageNames.PAYMENT_CALCULATOR,
        component: () => import(/* webpackChunkName: "paymentCalculator" */ '@/pages/marketing/PaymentCalculator.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.MOBILE_APPS,
        name: marketingPageNames.MOBILE_APPS,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/MobileApps.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LICENSES,
        name: marketingPageNames.LICENSES,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/Licenses.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.SURVEY_THANK_YOU,
        name: marketingPageNames.SURVEY_THANK_YOU,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/survey/ThankYou.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.ADD_REVIEW,
        name: marketingPageNames.ADD_REVIEW,
        component: () => import(/* webpackChunkName: "reviewsGathering" */ '@/pages/marketing/AddReview.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.ADD_REVIEW_PARAMETERIZED,
        redirect: (to) => {
            return { path: marketingPageNames.ADD_REVIEW, query: { p: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.REALESTATE_AGENTS,
        name: marketingPageNames.REALESTATE_AGENTS,
        component: () => import(/* webpackChunkName: "realEstateAgents" */ '@/pages/marketing/RealEstateAgents.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.FAQ,
        name: marketingPageNames.FAQ,
        beforeEnter(to, from, next) {
            return goToPageWithCleanState('http://support.aven.com')
        },
        meta: { public: true },
    },
]

export const marketingPageRoutes: RouteConfig[] = [...infoPageRoutes]
